<template>
    <div class="createTime-line-wrapper">
        <div class="createTime-line-item" v-for="(item, index) of processList" :key="index">
            <div class="circle" :class="{active: status >= item.status}"></div>
            <div class="nodeName black">{{ item.desc }}</div>
            <div class="time">{{ item.time }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        processList: {
            type: Array,
            default: ()=>[]
        },
        status: {
            type: [String, Number],
            default: ''
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.createTime-line-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 110px;
        margin: 0 20px;
        border-bottom: 1px dashed #e5e6ec;
        .createTime-line-item {
            margin-right: 80px;
            text-align: center;
            .circle {
                position: relative;
                width: 8px;
                height: 8px;
                margin: 0 auto 8px;
                border-radius: 50%;
                background: #e5e6ec;
                &.active {
                    width: 12px;
                    height: 12px;
                    // background: #fd4845;
                    background: #20ceab;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 16px;
                        width: 102px;
                        height: 2px;
                        // background: #fd4845;
                        background: #20ceab;
                        transform: translateY(-50%);
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 16px;
                        width: 102px;
                        height: 2px;
                        // background: #fd4845;
                        background: #20ceab;
                        transform: translateY(-50%);
                    }
                }
            }
            
            &:first-child {
                .circle {
                    &.active {
                        &:before {
                            content: none;
                        }
                    }
                }
            }
            &:last-child {
                .circle {
                    &.active {
                        &:after {
                            content: none;
                        }
                    }
                }
            }
            .nodeName {
                margin-bottom: 8px;
                color: #828c96;
            }
            .time {
                width: 140px;
                height: 20px;
                color: #b7bcc4;
            }
        }
    }
</style>