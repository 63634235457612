<template>
    <transition name="fade">

        <div v-if="isPopupShow" class="modal-box">
            <div class="modal-content" :style="'width: ' + modalWidth + 'px'">
                <div class="title" :class="{multi: headerText}">
                    <h4 v-if="headerText">{{headerText}}</h4>
                    <i @click="handleClosePopup" class="el-icon-close"></i>
                </div>
                <!-- <div class="main" v-if="checkModalBox.type=='cancel'"> -->
                <div class="main">
                    <slot />
                    <div class="btn-box">
                        <div v-if="confirmText" class="btn-wrap">
                            <button @click="handleConfirmButton" class="active" :class="{'red-confirm': redConfirm}">{{confirmText}}</button>
                        </div>
                        <div v-if="cancelText" class="btn-wrap">
                            <button @click="handleCancelButton" class="default" :class="{'red-button': redCancel}">{{cancelText}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </transition>
</template>

<script>
export default {
    props: {
        modalWidth: {
            type: Number,
            default: 600
        },
        headerText: {
            type: String,
            default: ''
        },
        redConfirm: {
            type: Boolean,
            default: false
        },
        redCancel: {
            type: Boolean,
            default: false
        },
        isPopupShow: {
            type: Boolean,
            default: false
        },
        confirmText: {
            type: String,
            default: '确认取消'
        },
        cancelText: {
            type: String,
            default: '我再想想'
        }
    },
    methods: {

        handleConfirmButton () {
            this.$emit('handleConfirmButton')
        },

        handleCancelButton () {
            this.$emit('handleCancelButton')
        },

        handleClosePopup () {
            this.$emit('handleClosePopup')
        }

    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.modal-box {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .modal-content {
        background: white;
        // width: 31rem;
        width: 48rem;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        .title {
            text-align: right;
            padding: 15px 10px;
            &.multi {
                display: flex;
                justify-content: space-between;
                border-bottom: solid 1px #eaeaee;
            }
            h4 {
                padding: 0 10px;
                border-left: solid 2px #20ceac;
                margin: 0 10px;
            }
            i {
                color: #bebebe;
                font-size: 20px;
            }
            i:hover {
                cursor: pointer;
                color: #45e7c7;
            }
        }
        .main {
            padding: 15px 50px;
            line-height: 24px;
            .btn-box {
                display: flex;
                justify-content: center;
                margin: 40px 0 25px;
                .btn-wrap {
                    flex: 1 1 50%;
                    box-sizing: border-box;
                    padding: 0 10px;
                }
                button {
                    width: 100%;
                    border: none;
                    outline: 0;
                    background: white;
                    font-size: 16px;
                    padding: 0.7rem 2.8rem;
                    border-radius: 5px;
                }
                button.active {
                    background: #20ceab;
                    color: white;
                }
                button.active.red-confirm {
                    background: #ee685d;
                }
                button.red-button {
                    background: #ee685d;
                    color: white;
                    border: none;
                }
                button.default {
                    border: 1px solid #d0d0d0;
                }
            }
        }
    }
}
</style>