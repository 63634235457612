<template>
    <div class="payment-box">
        <h4>{{ title }}</h4>
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bg: {
            type: String,
            default: '#ffffff'
        },
        title: {
            type: String,
            default: ''
        },
    },
    data () {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.payment-box {
    margin: 15px;
    box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.3);
    h4 {
        background: #f5f5f5;
        padding: 10px 15px;
        // padding: 0px 15px;
        width: 100%;
        // font-size: 30px;
    }
    .content {
        width: 100%;
        // min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
    // div {
    //     font-size: 14px;
    //     display: flex;
    //     flex-wrap: wrap;
    //     padding: 10px 0;
    //     span {
    //         margin: 10px 15px;
    //         &.red {
    //             margin: 0px 0px;
    //         }
    //     }
    // }
}
</style>