import { Message, MessageBox } from 'element-ui'  //引用饿了么UI消息组件
import { getToken } from '@/common/auth'
import axios from 'axios' //引用axios
import store from '../store'
import { Loading } from 'element-ui';
// 这几个接口请求完成前页面中间会转圈，完毕后圆圈才消失，防止重复点击按钮，重复请求接口
import config  from '@/common/config.js'
// console.log(config)
const loadUrl = [
    'user/login.html',
    'user/sms-login.html',
    'user/get-user-info.html',
    'user/send.html',
    'user/register.html',
    'user/register-store.html',
    'smanager/confirm-acceptance.html',
    'smanager/commit-evaluate.html',
    'smanager/urge-master.html',
    'smanager/cancel-order.html',
    'smanager/use-master.html',
    'smanager/orderadd.html'
]
const request = axios.create({
      baseURL: config.baseUrl,
    //   baseURL: 'http://ipc.daijige.com/',
    //   baseURL: 'https://demo.daijige.com/',
    //  withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000
})
let loadingInstance
request.interceptors.request.use(
    config => {
        // console.log(config)
        if (loadUrl.includes(config.url)) {
            loadingInstance = Loading.service({ fullscreen: true });
        }
        // config.headers['terminal'] = 3
        if (getToken()) {
            config.headers['token'] = getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

request.interceptors.response.use(
    response => {
        return new Promise((resolve, reject) => {
            // console.log(response)
            if (loadingInstance) {
                // setTimeout(() => {
                loadingInstance.close();
                // }, 3000)
            }
            // console.log('4444444444444')
            // console.log(response)
            const res = response.data
            // console.log(res)
            // 特殊情况 支付宝订单付款/补款 支付宝钱包充值 三个接口 返回的res直接是一个form表单字符串，里面没有status、message、data，这里先处理
            if (/<form id=/.test(res)) {
                return resolve(res)
            }
            if (res.status !== 1 && res.code !== 1) {
                // console.log('4444444444--11111111111')
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 3 * 1000
                })
                if (res.status == 401) {
                    // console.log('4444444444--22222222222')
                    // token错误的情况下会到这里
                    MessageBox.confirm('登录后才能查看该内容', '确定登录', {
                        confirmButtonText: '去登陆',
                        // cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // console.log('11111111111')
                        store.dispatch('user/resetToken').then(() => {
                            // location.reload()
                            location.href = '/login/'
                        })
                    })
                }
                // console.log('444444444444-333333')
                // return Promise.reject(new Error(res.msg || 'Error'))
                return reject(res.msg)
            }
            else {
                // console.log('5555555555555555555555555555')
                return resolve(res.data)
            }
        })
        
    },

    error => {
        // console.log('66666666666666666666666666666')
        Message({
            message: '网络出错了',
            type: 'error',
            duration: 3 * 1000
        })
        return Promise.reject(error)
    }
)

export default request // 导出封装后的axios
