import Vue from 'vue'

Vue.prototype.$api = {
    category: {
		firstcategorys: 		'/category/firstcategorys.html, get', 	  // 一级类目
		nextcategorys: 			'/category/nextcategorys.html, get', 	  // 二三级类目
		// selectgoodstemplate: 	'/category/selectgoodstemplate.html, get',// 选择商品模板
        getAttr:                '/category/get-attr.html, get', // 选择商品属性
        getSource:              '/category/get-source.html, get', // 获取报价种类

        getcategory:            '/category/getcategory.html, post', // 快速下单一级类目数组
        getcategorys:           '/category/getcategorys.html, post', // 快速下单一级类目某项

		getsubcategory:			'/category/getsubcategory.html, post' // 获取级联二三级类目
	},
	servertype: {
		categoryserver: 		'/servertype/categoryserver.html, get', // 查询服务类型列表
	},
	fixedOrder: {
		// nextcategorys:			'/fixed-order/nextcategorys.html, get', // 选择一口价二三级类目
        // getAttr:                '/fixed-order/get-attr.html, get', // 选择商品属性
        // getSource:              '/fixed-order/get-source.html, get', // 获取报价种类
        convertBid:             '/fixed-order/convert-bid.html, post' // 转化竞价单
	},
	serviceInfo: {
		city: 					'/service-info/city.html, get', // 查询省市区
	},
	discount: {
		orderDiscount: 			'/discount/order-discount.html, get', // 折扣
	},
	dataIdentify: {
		dataIdentify: 			'/data-identify/data-identify.html, post', // 识别个人地址电话名称信息
	},
	index: {
        discountLotteryStart:   '/index/discount/lotteryStart.html', // 抽奖开启

		orderGuide: 			'/index/order-guide.html, get', // 查询下单指引
		masterList: 			'/index/master-list.html, get', // 查询师傅列表
		processSupervision: 	'/index/process-supervision.html, get', // 查询师傅列表
		masterNumberByProvince: '/index/master-number-by-province.html, post', // 查询师傅地图（全国）
		channelNext: 			'/index/channel-next.html, get', // 查询服务保障
		help: 					'/index/help.html, get', // 查询帮助
		channelContent: 		'/index/channel-content.html, get', // 新闻动态
		getNews: 				'/index/get-news.html, get', // 新闻详情
		hotspot: 				'/index/hotspot.html, get', // 热点资讯
		indexBanner: 			'/index/index-banner.html, get', // 海报
		masterServiceProcess: 	'/index/master-service-process.html, get', // 师傅服务流程
		masterResources: 		'/index/master-resources.html, post', // 师傅省份数量等
		vipList: 				'/index/vip-list.html, get', // vip页面
	},
	message: {
		msgTypeList: 			'/message/msg-type-list.html, get', // 查询消息各类型数量
		msgList: 				'/message/msg-list.html, get', // 查询消息列表
		read: 					'/message/read.html, get', // 查询点击已读
		question:				'/message/question.html, get', //师傅问题
		getSmsUrl:				'/message/get-sms-url.html, post', // 获取短信内容（h5跳转师傅端app路径)
		oneClickClear:			'/message/one-click-clear.html' // 一键清除未读消息
	},
	order: {
		payOrder: 				'/order/pay-order.html, post', // 支付宝微信付款
		orderReplenishment: 	'/order/order-replenishment.html, post', // 支付宝微信师傅补款
		offerMaster: 			'/order/offer-master.html, post', // 获取雇佣师傅列表
        replenishmentRefuse:    '/order/replenishment-refuse.html, post', // 拒绝师傅补款
        replenishment:  		'/order/replenishment.html, post', // 商家发起补款
		// thirdReplenishmentCancel:'/order/third-replenishment-cancel.html, post', // 商家发起的补款撤销
	
		historyOrder:			'/order/history-order.html, post', // 下单历史记录
	},
	payres: {
		checkPayStatus: 			 '/payres/check-pay-status.html, get', // 查询支付宝微信付款状态
		checkPayStatusReplenishment: '/payres/check-pay-status-replenishment.html, get', // 查询支付宝微信补款状态
	},
	purse: {
		checkWalletPassword: 	'/purse/check-wallet-password.html, post', // 查询钱包付款状态
		walletPay: 				'/purse/wallet-pay.html, post', // 钱包付款
		walletReplenishment: 	'/purse/wallet-replenishment.html, post', // 钱包补师傅补款
		walletCoupon: 			'/purse/wallet-coupon.html, get', // 查询优惠券列表
		amountConsumption: 		'/purse/amount-consumption.html, post', // 查询月周日消费
		myPurse: 				'/purse/my-purse.html, get', // 钱包收支数据列表
		initialPassword: 		'/purse/initial-password.html, post', // 首次重置钱包密码
		repassword: 			'/purse/repassword.html, post', // 修改钱包密码
		walletRecharge: 		'/purse/wallet-recharge.html, post', // 钱包支付宝微信充值
	},
	report: {
		dataReport: 			'/report/data-report.html, get', // 查询数据报表
		generateApp: 			'/report/generate-app.html, get', // 商家端扫码下载APP用，目前没用到
	},
	rightsProtection: {
		rpAdd: 					'/rights-protection/rp-add.html, post', // 提交维权表单
		rpSchedule: 			'/rights-protection/rp-schedule.html, get', // 查询此订单维权信息
		rpList: 				'/rights-protection/rp-list.html, get', // 查询维权列表
		rpBack: 				'/rights-protection/rp-back.html, get', // 
        rpCancel:               '/rights-protection/rp-cancel.html' // 撤回投诉
	},
	smanager: {
		orderTag:				'/smanager/order-tag.html, get', // 订单标记列表
		orderList: 				'/smanager/order-list.html, get', // 订单列表
		getOrderNumberBystatus: '/smanager/get-order-number-bystatus.html, get', // 订单列表类型数量角标
		orderDetail: 			'/smanager/order-detail.html, get', // 订单详情
		orderMyEvaluate: 		'/smanager/order-my-evaluate.html, get', // 评价列表
		confirmAcceptance: 		'/smanager/confirm-acceptance.html, get', //商家端 待取货->待上门 待验货->待完工 待验收 -> 待评价(1001)
		commitEvaluate: 		'/smanager/commit-evaluate.html, post', // 评价师傅
		urgeMaster: 			'/smanager/urge-master.html, post', // 催促师傅
		useMaster: 				'/smanager/use-master.html, post', // 雇佣师傅
		cancelOrder: 			'/smanager/cancel-order.html, post', // 取消订单
		orderAdd: 				'/smanager/orderadd.html, post', // 下单
		unit: 					'/smanager/unit.html, get', // 获取商品单位
		masterComplateImages: 	'/smanager/master-complate-images.html, get', // 师傅施工图列表
		masterComment: 			'/smanager/master-comment.html, get', // 师傅评价列表
		masterInfo: 			'/smanager/master-info.html, get', // 师傅信息
		businessEnable:			'/smanager/business-enable.html',  // 商家注销账户
	},
    
	goodstemplate: {
		checkTemplateName:  	'/goodstemplate/check-template-name.html, post', // 检查模板名称是否重复
		// addgoodstemplate: 		'/goodstemplate/addgoodstemplate.html, post', 	 // 新增模板
	},
	toolbox: {
		goodsTemplate: 			'/toolbox/goods-template.html, get', // 查询商品模板列表
        goodstemplateAdd: 		'/toolbox/goods-template-add.html, post', 	 // 新增模板
		goodsTemplateEdit: 		'/toolbox/goods-template-edit.html, post', // 编辑商品模板
		goodsTemplateDelete: 	'/toolbox/goods-template-delete.html, get', // 删除商品模板
		
		goodsImage: 			'/toolbox/goods-image.html, get', // 查询商品图库列表
		goodsImageDelete: 		'/toolbox/goods-image-delete.html, get', // 删除商品图
		goodsImageEdit: 		'/toolbox/goods-image-edit.html, post', // 编辑商品图
		goodsImageAdd: 			'/toolbox/goods-image-add.html, post', // 新增商品图
		
		finishedGallery: 		'/toolbox/finished-gallery.html, get', // 查询完工图库
		finishedGalleryDelete:  '/toolbox/finished-gallery-delete.html, get', // 删除完工图
		
		myMaster: 				'/toolbox/my-master.html, post', // 查询我的师傅列表
		banMaster: 				'/toolbox/ban-master.html, get', // 拉黑师傅
	},
	user: {
		login: 					'/user/login.html, post', // 登录
		smsLogin: 				'/user/sms-login.html, post', // 短信登录
		logout: 				'/user/logout.html, get', // 登出
		getUserInfo: 			'/user/get-user-info.html, get', // 查询用户信息
		editUserInfo: 			'/user/edit-user-info.html, post', // 编辑用户信息
		
		send: 					'/user/send.html, get', // 发送验证短信
		register: 				'/user/register.html, post', // 注册
		registerStore: 			'/user/register-store.html, post', // 注册店铺
		
		addChildUser: 			'/user/add-child-user.html, post', // 新增子账号
		editChildUser: 			'/user/edit-child-user.html, post', // 编辑子账号
		deleteChildUser: 		'/user/delete-child-user.html, get', // 删除子账号
		
		childUser: 				'/user/child-user.html, get', // 查询子账号列表
		childUserRule: 			'/user/child-user-rule.html, get', // 
		childUserRuleSave: 		'/user/child-user-rule-save.html, post', // 
		
		changePwd: 				'/user/change-pwd.html, post', // 修改用户密码
		oldPhoneCheck: 			'/user/old-phone-check.html, post', // 校验当前账号密码输入是否正确
		changePhone: 			'/user/change-phone.html, post', // 绑定新手机号
	},
	news: {
		mailImage: 				'/news/mail-image.html, post', // 获取短信跳转页背景
	},
	edition: {
		check: 					'/edition/check.html, post', 			  // 版本检查
	},
}