<template>
    <div class="condition">
        <el-form v-if="isSearchOpen" label-position="top" label-width="80px" :model="searchOrder">
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="客户信息">
                        <el-input v-model="searchOrder.client_name_tel" placeholder="请输入您的客户姓名或手机号"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="师傅信息">
                        <el-input v-model="searchOrder.master_name_tel" placeholder="请输入师傅姓名或者手机号"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="订单编号">
                        <el-input v-model="searchOrder.order_po" placeholder="请输入您的订单编号"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="订单模式">
                        <!-- <el-input v-model="searchOrder.offer_type" placeholder="请输入您的订单编号"></el-input> -->
                        <el-select clearable v-model="searchOrder.offer_type" placeholder="请选择订单模式">
                            <el-option v-for="item in offerTypeList" :key="item.type" :label="item.name" :value="item.type" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
            <el-form-item label="下单账户">
                <el-input v-model="searchOrder.region" placeholder="选择子账号"></el-input>
            </el-form-item>
        </el-col> -->
            </el-row>
            <el-form-item label="客户省份">
                <Address ref="address" />
            </el-form-item>
            <el-form-item v-if="hasTime" label="报价时间">
                <el-col :span="7">
                    <el-date-picker v-model="searchOrder.start_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始日期时间">
                    </el-date-picker>&nbsp;&nbsp;&nbsp;&nbsp;-
                </el-col>
                <el-col :span="8">
                    <el-date-picker v-model="searchOrder.end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择结束日期时间">
                    </el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item class="btn-box">
                <el-button @click="findList" class="search">搜索</el-button>
                <el-button @click="resetSearch" class="search reset">重置选项</el-button>
            </el-form-item>
        </el-form>

        <div class="demo-block-control" style="left: 0px;" @click="toggleSearch">
            <i :class="isSearchOpen ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
            <span>{{ isSearchOpen ? '收起搜索' : '打开搜索'}}</span>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        hasTime: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isSearchOpen: false,
        searchOrder: {
            client_name_tel: '',
            master_name_tel: '',
            order_po: '',
            client_province: '',
            client_city: '',
            start_time: '',
            end_time: '',
            master_id: '', // 只有在我的师傅，查看该师傅为我服务过的订单，跳转到订单列表页面 query才会携带masterId参数
            offer_type: ''
        },

        offerTypeList: [{
                name: '竞价',
                type: 1,
            },
            {
                name: '一口价',
                type: 2,
        }],
        addressInfo: {
            province_id: '',
            city_id: '',
            district_id: '',
            province_name: '',
            city_name: '',
            district_name: '',
        },
    }),
    created () {
        if (this.$route.query.masterId) {
            this.searchOrder.master_id = this.$route.query.masterId
        }
    },
    methods: {
        toggleSearch () {
            this.isSearchOpen = !this.isSearchOpen
        },
        resetSearch () {
            this.searchOrder = {
                client_name_tel: '',
                master_name_tel: '',
                order_po: '',
                client_province: '',
                client_city: '',
                start_time: '',
                end_time: '',
                master_id: '',
                offer_type: ''
            }
            this.addressInfo.province_id = ''
            this.addressInfo.city_id = ''
            this.addressInfo.district_id = ''

            this.findList()
        },
        findList () {
            this.searchOrder.client_province = this.addressInfo.province_id
            this.searchOrder.client_city = this.addressInfo.city_id
            this.$emit('findList')
        },
    }
}
</script>

<style lang="scss" scoped>
/* 条件搜索 */
.condition {
    padding: 0 20px;
}
.btn-box {
    margin-top: 50px;
}
.search {
    box-sizing: border-box;
    outline: 0;
    padding: 12px 70px;
    background: #20ceab;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
}
.search.reset {
    outline: 0;
    background: transparent;
    color: #20ceab;
}

.demo-block-control {
    position: relative;
    transition: 0.2s;
    height: 32px;
    margin-bottom: 10px;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: center;
    margin-top: -1px;
    color: #d3dce6;
    cursor: pointer;
    & > i {
        position: absolute;
        right: 0;
        color: #20ceab;
        font-size: 16px;
        line-height: 32px;
        // line-height: 44px;
        transition: 0.3s;
    }
    & > span {
        position: absolute;
        right: 0%;
        color: #20ceab;
        transform: translateX(-30px);
        font-size: 14px;
        line-height: 32px;
        // line-height: 44px;
        transition: 0.3s;
    }
}
</style>