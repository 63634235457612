<template>

    <div class="notice">
        <!-- <i class="iconfont icongonggao1"></i> -->
        <!-- <i class="el-icon-s-flag"></i> -->
        {{title}}
    </div>

</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        }
    },
}
</script>

<style lang="scss" scoped>
.notice {
    background: #ffe4e2;
    padding: 0.6rem 1.2rem;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    // margin: 10px 14px 0;
    margin-bottom: 10px;
    i {
        color: #ff4646;
        font-size: 24px;
        margin-right: 0.5rem;
        font-weight: bold;
    }
}
</style>