<template>
    <div class="bottomOrder" :class="{'bg': bottomInfo.bg == 'black'}">
        <h3>售后烦、售后难，让呆鸡哥帮你妥善解决！</h3>
        <div>
            <span v-if="bottomInfo.type == 'order'" @click="gotoOrder()">立即下单</span>
            <span v-if="bottomInfo.type == 'app'">下载APP</span>
            <span v-if="bottomInfo.type == 'kefu'">联系客服</span>
            <div v-if="bottomInfo.type == 'app'" class="erweima">
                <p>手机扫一扫</p>
                <!-- <img src="@/assets/images/picture/master_qr.png" alt=""> -->
                <img src="@/assets/images/picture/www_daijige_qr.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        bottomInfo: Object
    },
    computed: {
        ...mapGetters({
            userId: 'userId'
        })
    },
    methods: {
        gotoOrder () {
            console.log(this.$route)
            // if (!this.userId) {
            //   this.$router.push({path: '/login/'})
            //   return
            // }
            if (!this.userId) {
                // this.$confirm('登录后立即下单', '确定登录', {
                //   confirmButtonText: '去登录',
                //   cancelButtonText: '取消',
                //   confirmButtonClass: 'btnFalses',
                //   type: 'warning'
                // }).then(() => {
                // this.$router.push({path: '/login/', query: {
                //   place: true
                // }})
                this.$router.push({
                    path: this.$route.path, query: {
                        log_type: 'pwd_login',
                        place: true
                    }
                })
                // }).catch(err => {
                // })
                return;
            }
            //   if (sessionStorage.getItem("orderInfo")) {
            //     sessionStorage.removeItem("orderInfo");
            //     // sessionStorage.removeItem("activeData");
            //   }
            this.$router.push({ path: '/place' })
        },
    }
}
</script>

<style scoped>
.bottomOrder {
    padding: 50px 0;
    text-align: center;
    background: #20ceab;
    color: white;
}

.bg {
    background: #585253;
}

.bottomOrder h3 {
    padding: 0 0 30px 0;
}

.bottomOrder > div {
    display: inline-block;
    position: relative;
}

.bottomOrder > div:hover .erweima {
    display: block;
}

.bottomOrder > div:hover .erweima img {
    width: 220px;
    height: 220px;
    /* margin: 0 10px; */
    text-align: center;
}

.bottomOrder > div span {
    padding: 10px 20px;
    border: 1px solid #ffffff;
    cursor: pointer;
}

.bottomOrder .erweima {
    position: absolute;
    left: 50%;
    top: 2.7rem;
    background: white;
    padding: 0.8rem;
    transform: translate(-50%, 0);
    display: none;
}

.erweima:after {
    content: "";
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: transparent transparent white;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translate(-50%, 0);
}

.bottomOrder .erweima p {
    color: #ff8d21;
    font-size: 12px;
}
</style>