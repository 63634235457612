import Vue from 'vue'
import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css

import App from './App.vue'
import router from './router'
import './permission' // permission control

const requireComponents = require.context('./components', true, /\.vue/);
requireComponents.keys().forEach(filePath => {
    const component = requireComponents(filePath)
    const componentName = filePath.split('/')[filePath.split('/').length - 1].split('.')[0]
    Vue.component(componentName, component.default || component)
})

Vue.use(Element, {
    size: Cookies.get('size') || 'medium' // set element-ui default size
    // locale: enLang // 如果使用中文，无需设置，请删除
})

// import common from './common/common copy'
import confirm from './common/confirm'
import fetch from './common/fetch'
import func from './common/func'
import api from './common/api'
import constant from './common/constant'
Vue.prototype.$constant = constant
import store from './store'
Vue.prototype.$store = store

import * as filters from './filters' // global filters
// console.log(filters)
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

import './icons'
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
