<template>
    <div class="coupon">
        <!-- <h3>优惠券</h3> -->
        <div class="toggle">
            <span @click="toggleCouponContent" class="mr10">{{ isShowContent ? '-' : '+' }}</span>
            使用优惠券
        </div>

        <div class="coupon-box" v-show="isShowContent">

            <div v-if="Discountmoney.rid" class="comment-main">
                <div class="commentMainItem">
                    <span>￥{{ Discountmoney.discount_money }}</span>
                    <span>优惠券</span>
                    <span v-if="Discountmoney && Discountmoney.discount_type_id == 1">满{{ Discountmoney.fspanl_money }}可用</span>
                    <span v-if="Discountmoney && Discountmoney.discount_type_id == 2">无门槛</span>
                    <span>有效期至 : {{ Discountmoney.endtime | parseTime('{y}-{m}-{d}') }}</span>
                    <span v-if="Discountmoney.checktime == '1'">即将过期</span>
                    <span v-if="!(Discountmoney.checktime == '1')"></span>
                </div>
            </div>

            <div v-else :style="{ border: '1px solid #e3e3e3' }">
                <ul class="nav">
                    <li @click="swichTag('1')" :class="{ 'active': couponType == '1' }">可用优惠券 ({{ validCouponCount }})</li>
                    <li @click="swichTag('2')" :class="{ 'active': couponType == '2' }">不可用优惠券({{ invalidCouponCount }})</li>
                </ul>

                <ul class="comment-main">
                    <div class="commentMainItem" v-for="item in CouponInfo.list" :key=item.rid @click="sendDiscountmoney(item)">
                        <span>￥{{ item.discount_money }}</span>
                        <span>优惠券</span>
                        <span v-if="item.discount_type_id == 1">满{{ item.full_money }}可用</span>
                        <span v-if="item.discount_type_id == 2">无门槛</span>
                        <span>有效期至 : {{ item.endtime | parseTime('{y}-{m}-{d}') }}</span>
                        <span v-if="item.checktime == '1'">即将过期</span>
                        <span v-if="!(item.checktime == '1')"></span>
                    </div>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        orderInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        Discountmoney: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data: () => ({
        isShowContent: true,
        couponType: '1',
        CouponInfo: {
            all_count: 0,
            totalCount: 0
        },
    }),
    computed: {
        validCouponCount() {
            if (this.couponType == '1') {
                return this.CouponInfo.totalCount
            } else if (this.couponType == '2') {
                return this.CouponInfo.all_count - this.CouponInfo.totalCount
            }
            return 0
        },
        invalidCouponCount() {
            if (this.couponType == '1') {
                return this.CouponInfo.all_count - this.CouponInfo.totalCount
            } else if (this.couponType == '2') {
                return this.CouponInfo.totalCount
            }
            return 0
        }
    },
    created() {
        this.getCoupon(1)
    },
    
    watch: {
        // 'orderInfo.order_id': {
        //     handler(val) {
        //         this.getCoupon(1)
        //     }
        // }
    },
    methods: {

        // 使用优惠券
        sendDiscountmoney(item) {
            if (this.couponType == '1') {
                console.log(item)
                this.$emit('sendDiscountmoney', item)
            }
        },

        // 打开/关闭优惠券框
        toggleCouponContent(fg) {
            this.isShowContent = !this.isShowContent
            if (this.isShowContent) {
                this.getCoupon(1)
            } else {
                this.$emit('sendDiscountmoney', {
                    discount_money: '',
                    rid: ''
                })
            }
        },

        // 切换可用/不可用优惠券
        swichTag(couponType) {
            this.couponType = couponType;
            this.getCoupon(couponType)
        },

        async getCoupon(couponType) {
            const params = {
                type: couponType,
                category: this.orderInfo.order_id,
                price: this.orderInfo.order_price,
            }
            let data = await this.$fetch(this.$api.purse.walletCoupon, params)
            this.CouponInfo = data;
            console.log(this.CouponInfo)
        }

    }
}
</script>

<style lang="scss" scoped>
.coupon {
    width: 100%;
    margin: 20px 0;
    font-weight: 100;
    h3 {
        font-weight: normal;
        margin: 5px 0;
    }
    .toggle {
        margin-bottom: 20px;
        span {
            cursor: pointer;
            color: white;
            padding: 0 5px;
            background: #20ceab;
        }
    }

    .coupon-box {
        .nav {
            display: flex;
            padding: 0 0 !important;
            border: none;
            li {
                cursor: pointer;
                width: 141px;
                height: 42px;
                display: flex;
                /* padding: 0.5rem 0; */
                padding: 0 0 !important;
                align-items: center;
                justify-content: center;
                border-right: solid 1px #e3e3e3;
                border-bottom: solid 1px #e3e3e3;
                &.active {
                    border-bottom: none;
                    color: #20ceab;
                }
            }
        }
    }

    .comment-main {
        margin-top: 20px;
        border: none;
        padding: 0 0 !important;

        .commentMainItem {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            height: 50px;
            background: #f5f5f5;
            margin-bottom: 10px;
            font-size: 14px;
            background: #f5f5f5;
            span {
                flex: 1;
                text-align: center;
            }
        }
    }
}
</style>