<template>
    <footer :class="{'active': footInfo && footInfo.bg=='black'}">
        <div>
            <div>
                <img class="chicken" :src="footInfo && footInfo.bg=='black' ? LOGOImg2 : LOGOImg1" alt="">
                <p>服务热线：0573-83377880</p>
                <!-- <p>周一至周日 9：00-21：00</p> -->
                <p>客服工作时间：工作日8:30-11:30 13:00-17:30</p>
                <p>欢迎您的来电资询</p>
            </div>
            <div class="nav">
                <ul>
                    <li>
                        <h4>
                            <router-link to='/about/'>关于我们</router-link>
                        </h4>
                        <p>
                            <router-link to='/about/'>关于呆鸡哥</router-link>
                        </p>
                        <p>
                            <router-link to="/contactUs/">联系我们</router-link>
                        </p>
                    </li>
                    <li>
                        <h4>
                            <router-link to="/news/">资讯中心</router-link>
                        </h4>
                        <!-- <p v-for="item of tabList" :key="item.news_type_id" @click="switchNewsType(item.news_type_id)">
              {{item.title}}
            </p> -->
                        <p>
                            <router-link to="/news/?newsType=4">市场动态</router-link>
                        </p>
                        <p>
                            <router-link to="/news/?newsType=3">行业动态</router-link>
                        </p>
                        <p>
                            <router-link to="/news/?newsType=6">呆鸡学院</router-link>
                        </p>
                        <p>
                            <router-link to="/news/?newsType=2">平台新闻</router-link>
                        </p>
                    </li>
                    <li>
                        <h4>
                            <router-link to="/help/">帮助中心</router-link>
                        </h4>
                        <p>
                            <router-link to="/help/">常见问题</router-link>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="qrCode">
                <div>
                    <img src="@/assets/images/picture/erweima.png" alt="">
                    <p>微信公众号</p>
                </div>
                <div>
                    <img src="@/assets/images/picture/www_daijige_qr.png" alt="">
                    <p>师傅端APP</p>
                </div>
            </div>
        </div>
        <div class="Reserved" :class="{'active': footInfo && footInfo.bg=='black'}">
            2019智乘（浙江）网络科技有限公司.All Rights Reserved |
            Copyright 浙ICP备19016427号-1
        </div>
    </footer>
</template>
<script>

export default {
    props: {
        footInfo: Object
    },
    data: () => ({
        tabList: [{
            title: '市场动态',
            news_type_id: 4,
        }, {
            title: '行业动态',
            news_type_id: 3,
        }, {
            title: '呆鸡学院',
            news_type_id: 6,
        }, {
            title: '平台新闻',
            news_type_id: 2,
        }],
        isActive: false,
        LOGOImg1: require('../../assets/images/picture/LOGOWhite.png'),
        LOGOImg2: require('../../assets/images/picture/LOGO@2x.png')
    }),
    created () {

    },
    mounted () {
        let vm = this;
        window.onscroll = function () {
            if (document.documentElement.scrollTop > 100) {
                vm.isActive = true;
            } else {
                vm.isActive = false;
            }
        }
        window.addEventListener("scroll", this.showbtn, true);
    },
    watch: {

    },
    methods: {

        // switchNewsType(id) {
        //   this.$router.push({ path: '/news/', query: {
        //     newsType: id,
        //   }})
        // },

        showbtn () {
            let that = this;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            that.scrollTop = scrollTop
        },
        /**
            * 回到顶部功能实现过程：
            * 1. 获取页面当前距离顶部的滚动距离（虽然IE不常用了，但还是需要考虑一下兼容性的）
            * 2. 计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是做一个减法
            * 3. 用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
            * 4. 最后记得在移动到顶部时，清除定时器
        */
        backtop () {
            var timer = setInterval(function () {
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) {
                    clearInterval(timer);
                }
            }, 30)
        }
    }
}

</script>
<style scoped>
.chicken {
    width: 95px;
    height: 46px;
}
footer {
    width: 100%;
    background: #262626;
    color: white;
}
footer.active {
    background: white;
    color: #545454;
}

footer > div:nth-child(1) {
    /* width: 60%; */
    width: 1200px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    padding: 60px 0;
}

P {
    margin: 18px 0;
}

.nav ul {
    display: flex;
}

.nav li {
    padding: 0 30px;
}

.nav li h4 a {
    color: #ffffff;
}

.nav li p a {
    color: #ffffff;
}

.nav li p a:hover,
.nav li h4 a:hover {
    color: #20ceab;
    /* cursor: pointer; */
}

.qrCode {
    display: flex;
    align-items: center;
}

.qrCode div {
    margin: 0 10px;
    text-align: center;
}

.qrCode div img {
    width: 110px;
    height: 110px;
    margin: 0 10px;
    text-align: center;
}

.qrCode p {
    margin: 10px 0;
}

.Reserved {
    padding: 20px 0;
    border-top: 1px solid #474747;
}

.Reserved.active {
    border-top: 1px solid #eaeaee;
}

footer > div:nth-child(2) {
    text-align: center;
}

.back-top {
    font-size: 30px;
    position: fixed;
    bottom: 100px;
    right: 50px;
    transition: all 0.5s;
}

.arrow {
    cursor: pointer;
    border: solid 2px #ccc;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 18px;
    color: gray;
    z-index: 999;
}
</style>
