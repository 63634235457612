<template>
    <el-row class="address" :gutter="gutter">

        <el-col :span="span">
            <el-select clearable v-model="addressInfo.province_id" placeholder="省份" @change="selProvince()">
                <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-col>

        <el-col :span="span">
            <el-select clearable v-model="addressInfo.city_id" placeholder="地市" @change="selCity()">
                <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-col>

        <el-col :span="span">
            <el-select clearable v-model="addressInfo.district_id" placeholder="区县" @change="selCounty()">
                <el-option v-for="item in districtList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-col>

    </el-row>
</template>

<script>

export default {
    props: {
        gutter: {
            type: Number,
            default: 20
        },
        addressInfo: {
            type: Object,
            default: () => {
                return {
                    province_id: '',
                    city_id: '',
                    district_id: '',
                    province_name: '',
                    city_name: '',
                    district_name: '',
                }
            }
        }
    },
    data: () => ({
        // province_id: '',
        // city_id: '',
        // district_id: '',
        // province_name: '',
        // city_name: '',
        // district_name: '',

        provinceList: [],
        cityList: [],
        districtList: []
    }),
    computed: {
        span() {
            // console.log(Math.ceil(this.gutter / 3))
            return Math.ceil(this.gutter / 3)
        },

        provinceMap() {
            let map = this.provinceList.reduce((acc, item) => {
                acc[item.id] = item
                return acc
            }, {})
            // console.log('123')
            // console.log(map)
            return map
        },

        cityMap() {
            let map = this.cityList.reduce((acc, item) => {
                acc[item.id] = item
                return acc
            }, {})
            // console.log(map)
            return map
        },

        districtMap() {
            let map = this.districtList.reduce((acc, item) => {
                acc[item.id] = item
                return acc
            }, {})
            // console.log(map)
            return map
        },

        province_name() {
            if (this.provinceMap[this.addressInfo.province_id]) {
                return this.provinceMap[this.addressInfo.province_id].name
            }
            return ''
        },

        city_name() {
            if (this.cityMap[this.addressInfo.city_id]) {
                return this.cityMap[this.addressInfo.city_id].name
            }
            return ''
        },

        district_name() {
            if (this.districtMap[this.addressInfo.district_id]) {
                return this.districtMap[this.addressInfo.district_id].name
            }
            return ''
        },
    },
    watch: {
        // gutter: {
        //     handler(val) {
        //         console.log(val)
        //     },
        //     deep: true,
        //     immediate: true
        // },
        province_name(val) {
            this.addressInfo.province_name = val
        },
        city_name(val) {
            this.addressInfo.city_name = val
        },
        district_name(val) {
            this.addressInfo.district_name = val
        },
        'addressInfo.province_id' (val) {
            console.log(val)
            // this.addressInfo.province_name = (this.provinceMap[val] && this.provinceMap[val].name) || ''
            if (val) {
                this.getCityList()
            }
        },
        'addressInfo.city_id' (val) {
            console.log(val)
            this.addressInfo.city_name = (this.cityMap[val] && this.cityMap[val].name) || ''
            if (val) {
                this.getDistrictList()
            }
        },
        'addressInfo.district_id' (val) {
            console.log(val)
            this.addressInfo.district_name = (this.districtMap[val] && this.districtMap[val].name) || ''
        },
        // addressInfo: {
        //     handler(val) {
        //         console.log(val)
        //     },
        //     deep: true,
        //     immediate: true
        // }
    },
    mounted () {
        this.getProvinceList()
    },
    methods: {

        selProvince (val) {
            console.log(val)
            this.addressInfo.city_id = ''
            this.addressInfo.district_id = ''
            this.cityList = []
            this.districtList = []
        },

        selCity (val) {
            console.log(val)
            this.addressInfo.district_id = ''
            this.districtList = []
        },

        selCounty () {
        },

        async getProvinceList () {
            const params = {
                level: 1
            }
            let data = await this.$fetch(this.$api.serviceInfo.city, params)
            this.provinceList = data;
        },

        async getCityList () {
            const params = {
                level: 2,
                pid: this.addressInfo.province_id
            }
            let data = await this.$fetch(this.$api.serviceInfo.city, params)
            this.cityList = data;
        },

        async getDistrictList () {
            const params = {
                level: 3,
                pid: this.addressInfo.city_id
            }
            let data = await this.$fetch(this.$api.serviceInfo.city, params)
            this.districtList = data;
        },

    }
}
</script>

<style lang="scss" scoped>
.address {
    & /deep/ .el-select {
        width: 100%;
    }
    & /deep/ .el-col-7:nth-child(1) {
        padding-left: 0 !important;
        // padding-right: 0 !important;
    }
    & /deep/ .el-col-7:last-child {
        padding-right: 0 !important;
        // padding-right: 0 !important;
    }
}
</style>