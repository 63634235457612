import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 解决：Uncaught (in promise) NavigationDuplicated; 
let originalPush = Router.prototype.push; // 先保存一份 Router.prototype.push方法
let originalRepace = Router.prototype.replace;
 
Router.prototype.push = function push(location) {
    // call(); 篡改上下文    catch(); 捕获异常 **** 
    return originalPush.call(this, location).catch(e => e);
}
Router.prototype.replace = function replace(location) {
    return originalRepace.call(this, location).catch(e => e);
}

/* Layout */
// import Layout from '@/layout'

/* Router Modules */
// import orderRouter from './modules/order'
// import helpRouter from './modules/help'
// import msgRouter from './modules/msg'
// import rpRouter from './modules/rp'
// import toolRouter from './modules/tool'
// import userRouter from './modules/user'
// import walletRouter from './modules/wallet'

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/',
        component: () => import('@/views/home/index'),
        hidden: true
    },
    {
        path: '/master',
        component: () => import('@/views/home/master'),
        hidden: true
    },
    {
        path: '/service',
        component: () => import('@/views/home/service'),
        hidden: true
    },
    {
        path: '/activity',
        component: () => import('@/views/home/activity'),
        hidden: true
    },
    {
        path: '/settle',
        component: () => import('@/views/home/settle'),
        hidden: true
    },
    {
        path: '/news',
        component: () => import('@/views/home/news'),
        hidden: true
    },
    {
        path: '/newsin',
        component: () => import('@/views/home/news/newsin'),
        hidden: true
    },
    {
        path: '/about',
        component: () => import('@/views/common/about'),
        hidden: true
    },
    {
        path: '/checkWx',
        component: () => import('@/views/common/checkWx'),
        hidden: true
    },
    {
        path: '/loadMaster',
        component: () => import('@/views/common/loadMaster'),
        hidden: true
    },
    {
        path: '/contactUs',
        component: () => import('@/views/common/contactUs'),
        hidden: true
    },
    {
        path: '/protocol/privacy',
        component: () => import('@/views/common/protocol/privacy'),
        hidden: true
    },
    {
        path: '/protocol/settle',
        component: () => import('@/views/common/protocol/settle'),
        hidden: true
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        hidden: true
    },
    //   {
    //     path: '/',
    //     component: Layout,
    //     redirect: '/dashboard',
    //     children: [
    //       {
    //         path: 'dashboard',
    //         component: () => import('@/views/dashboard/index'),
    //         name: 'dashboard',
    //         meta: { title: 'Dashboard', icon: 'dashboard', affix: true }
    //       }
    //     ]
    //   },
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
    {
        path: '/order/list',
        component: () => import('@/views/order/list'),
        hidden: true,
        meta: { keepAlive: true, title: '订单列表' }
    },
    // {
    //     path: '/order/problem',
    //     component: () => import('@/views/order/problem'),
    //     hidden: true
    // },

    {
        path: '/order/detail',
        component: () => import('@/views/order/detail'),
        hidden: true,
        meta: { title: '订单详情' }
    },
    {
        path: '/order/pay-success',
        component: () => import('@/views/order/pay-success'),
        hidden: true,
        meta: { title: '付款成功' }
    },


    {
        path: '/order/back',
        component: () => import('@/views/order/back'),
        hidden: true,
        meta: { title: '我的退款', noCache: false }
    },
    {
        path: '/order/rplist',
        component: () => import('@/views/order/rplist'),
        hidden: true,
        meta: { title: '投诉', noCache: false }
    },
    {
        path: '/order/rpdetail',
        component: () => import('@/views/order/rpdetail'),
        hidden: true,
        meta: { title: '投诉详情', noCache: false }
    },
    // {
    //     path: '/rp/complaint',
    //     component: () => import('@/views/rp/complaint'),
    //     hidden: true
    // },


    {
        path: '/wallet/success',
        component: () => import('@/views/wallet/success'),
        hidden: true,
        meta: { title: '充值成功', noCache: false }
    },
    {
        path: '/wallet/coupon',
        component: () => import('@/views/wallet/coupon'),
        hidden: true,
        meta: { title: '钱包优惠券', noCache: false }
    },
    {
        path: '/wallet/list',
        component: () => import('@/views/wallet/list'),
        hidden: true,
        meta: { title: '我的钱包', noCache: false }
    },
    {
        path: '/wallet/recharge',
        component: () => import('@/views/wallet/recharge'),
        hidden: true,
        meta: { title: '钱包充值', noCache: false },
    },

    {
        path: '/tool/report',
        component: () => import('@/views/tool/report'),
        hidden: true,
        meta: { title: '数据报表', noCache: false },
    },
    {
        path: '/order/my-evaluate',
        component: () => import('@/views/order/my-evaluate'),
        hidden: true,
        meta: { title: '我的评价', noCache: false }
    },
    {
        path: '/tool/master-info',
        component: () => import('@/views/tool/master-info'),
        hidden: true,
        meta: { title: '我的师傅', noCache: false }
    },
    {
        path: '/tool/complete-image',
        component: () => import('@/views/tool/complete-image'),
        hidden: true,
        meta: { title: '完工图库', noCache: false }
    },
    {
        path: '/tool/goods-image',
        component: () => import('@/views/tool/goods-image'),
        hidden: true,
        meta: { title: '商品图片库', noCache: false }
    },
    {
        path: '/tool/goods-template',
        component: () => import('@/views/tool/goods-template'),
        hidden: true,
        meta: { title: '商品模板管理', noCache: false }
    },
    {
        path: '/tool/my-master',
        component: () => import('@/views/tool/my-master'),
        hidden: true,
        meta: { title: '我的师傅', noCache: false }
    },

    {
        path: '/msg/message',
        component: () => import('@/views/msg/message'),
        hidden: true,
        meta: { title: '消息中心', noCache: false }
    },



    {
        path: '/user/child',
        component: () => import('@/views/user/child'),
        hidden: true,
        meta: { title: '子账号管理', noCache: false }
    },
    {
        path: '/user/roles',
        component: () => import('@/views/user/roles'),
        hidden: true,
        meta: { title: '角色权限设置', noCache: false }
    },
    {
        path: '/user/safe',
        component: () => import('@/views/user/safe'),
        hidden: true,
        meta: { title: '账号安全', noCache: false },
    },
    {
        path: '/user/base',
        component: () => import('@/views/user/base'),
        hidden: true,
        meta: { title: '基本资料', noCache: false }
    },


    {
        path: '/vip',
        component: () => import('@/views/home/vip'),
        hidden: true,
        meta: { title: '超级会员', noCache: false }
    },


    {
        path: '/help',
        component: () => import('@/views/help'),
        hidden: true,
        meta: { title: '帮助中心', noCache: false }
    },


    {
        path: '/place',
        component: () => import('@/views/place/index'),
        hidden: true,
        meta: { title: '下单', noCache: false }
    },
    {
        path: '/place/pay',
        component: () => import('@/views/place/pay'),
        hidden: true,
        meta: { title: '订单支付', noCache: false }
    },
    {
        path: '/place/success',
        component: () => import('@/views/place/success'),
        hidden: true,
        meta: { title: '下单成功', noCache: false }
    },
]
// export const asyncRoutes = [
//     orderRouter,
//     helpRouter,
//     msgRouter,
//     rpRouter,
//     toolRouter,
//     userRouter,
//     walletRouter,
//     { path: '*', redirect: '/404', hidden: true }
// ]

// 页面刷新时，重新赋值token
// if (sessionStorage.getItem('token')) {
//   store.commit('set_token', sessionStorage.getItem('token'))
// }

const createRouter = () => new Router({
    // mode: 'history', // 路由去掉#
    mode: 'hash',
    scrollBehavior: () => ({ y: 0 }),
    routes: [...constantRoutes, ...asyncRoutes]
})

const router = createRouter()


// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
