<template>
    <div class="side-nav" :class="{ 'openSidebar': sidebar.opened }">
        <logo v-if="showLogo" :collapse="isCollapse" backgroundColor="#304156" />
        <!-- <el-menu :default-active="activeDefault" :default-openeds="defaultOpeneds" active-text-color="#20CEAB" :collapse-transition="false" unique-opened @select="handleSelect" @open="handleOpen"> -->
        <el-menu 
            :default-openeds="defaultOpeneds"
            :collapse="isCollapse"
            :default-active="defaultActive"
            background-color="#304156" 
            text-color="#fff"
            unique-opened
            active-text-color="#20CEAB"
            :collapse-transition="false"
            @select="handleSelect" 
            @open="handleOpen"
        >
            <template v-for="(item, index) of ruleList">
                <el-submenu v-if="item.childs.length" :index="item.path" :key="index">
                    <template slot="title">
                        <!-- <div class="main-title-wrap" :class="{active: defaultOpeneds.includes('' + index)}"> -->
                        <div class="main-title-wrap" :class="{ 'hideSidebar': !sidebar.opened }">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.title }}</span>
                        </div>
                    </template>
                    <template v-if="item.childs.length">
                        <el-menu-item class="inner-title" v-for="(childItem, childIndex) of item.childs" :index="childItem.path" :key="childIndex" @click="linkTo(childItem)">
                            <span>{{ childItem.title }}</span>
                        </el-menu-item>
                    </template>
                </el-submenu>
                <el-menu-item v-else class="main-title" :index="item.path" :key="index" @click="linkTo(item)">
                    <i :class="item.icon"></i>
                    <span slot="title">{{ item.title }}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import constant  from '@/common/constant.js'
export default {
    data: () => ({
        showLogo: true,
        menuMap: ['order', 'rp', 'wallet', 'tool', 'msg', 'user'],
        ruleList: [
            {
                "title": "订单中心",
                "icon": "el-icon-s-order",
                "path": 'order',
                "childs": [
                    {
                        "title": "订单列表",
                        "icon": null,
                        "path": "/order/list",
                        "childs": []
                    },
                    {
                        "title": "投诉",
                        "icon": null,
                        "path": "/order/rplist",
                        "childs": []
                    },
                    {
                        "title": "我的退款",
                        "icon": null,
                        "path": "/order/back",
                        "childs": []
                    },
                    {
                        "title": "我的评价",
                        "icon": null,
                        "path": "/order/my-evaluate",
                        "childs": []
                    },
                ]
            },
            // {
            //     "title": "维权中心",
            //     "icon": "el-icon-guide",
            //     "path": null,
            //     "childs": [
            //     ]
            // },
            {
                "title": "我的钱包",
                "icon": "el-icon-money",
                "path": 'wallet',
                "childs": [
                    {
                        "title": "我的钱包",
                        "icon": null,
                        "path": "/wallet/list",
                        "childs": []
                    },
                    {
                        "title": "钱包优惠券",
                        "icon": null,
                        "path": "/wallet/coupon",
                        "childs": []
                    }
                ]
            },
            {
                "title": "工具箱",
                "icon": "el-icon-s-cooperation",
                "path": 'tool',
                "childs": [
                    // {
                    //     "title": "数据报表",
                    //     "icon": null,
                    //     "path": "/tool/report",
                    //     "childs": []
                    // },
                    {
                        "title": "商品模板管理",
                        "icon": null,
                        "path": "/tool/goods-template",
                        "childs": []
                    },
                    {
                        "title": "商品图片库",
                        "icon": null,
                        "path": "/tool/goods-image",
                        "childs": []
                    },
                    {
                        "title": "完工图库",
                        "icon": null,
                        "path": "/tool/complete-image",
                        "childs": []
                    },
                    {
                        "title": "我的师傅",
                        "icon": null,
                        "path": "/tool/my-master",
                        "childs": []
                    }
                ]
            },
            {
                "title": "账号管理",
                "icon": "el-icon-setting",
                "path": 'user',
                "childs": [
                    {
                        "title": "基本资料",
                        "icon": null,
                        "path": "/user/base",
                        "childs": []
                    },
                    {
                        "title": "账号安全",
                        "icon": null,
                        "path": "/user/safe",
                        "childs": []
                    },
                    // {
                    //     "title": "子账号管理",
                    //     "icon": null,
                    //     "path": "/user/child",
                    //     "childs": []
                    // },
                    // {
                    //     "title": "角色权限设置",
                    //     "icon": null,
                    //     "path": "/user/roles",
                    //     "childs": []
                    // }
                ]
            },
            // {
            //     "title": "消息中心",
            //     "icon": "el-icon-chat-line-round",
            //     "path": "/msg/message",
            //     "childs": []
            // },
            // {
            //     "title": "超级会员",
            //     "icon": "el-icon-medal",
            //     "path": "/vip/",
            //     "childs": []
            // },
            {
                "title": "帮助中心",
                "icon": "el-icon-question",
                "path": "/help/",
                "childs": []
            }
        ],
        // defaultActive: '/order/list'
    }),

    computed: {
        // indexStatus () {
        //     return function (index, childIndex, childItem) {
        //         // console.log("" + (index + 1) + "-" + (childIndex + 1), childItem.title)
        //         return "" + (index + 1) + "-" + (childIndex + 1);
        //     };
        // },
        defaultActive () {
            const path = this.$route.path;
            const ruleList = this.ruleList;
            if (!ruleList) return "";
            for (let i = 0, len = ruleList.length; i < len; i++) {
                if (path == ruleList[i].path) {
                    console.log(ruleList[i].path)
                    return ruleList[i].path;
                }
                for (let j = 0, jLen = ruleList[i].childs.length; j < jLen; j++) {
                    if (path == ruleList[i].childs[j].path) {
                        // console.log(ruleList[i].childs[j].path)
                        return ruleList[i].childs[j].path;
                    }
                }
            }
            return "";
        },
        ...mapGetters({
            defaultOpeneds: 'defaultOpeneds',
            sidebar: 'sidebar'
        }),
        isCollapse () {
            return !this.sidebar.opened
        }
    },

    mounted () {
        this.init()
    },
    methods: {
        init () {
            const path = this.$route.path.split('/');
            for (let i = 0, len = this.ruleList.length; i < len; i++) {
                if (path.includes(this.ruleList[i].path)) {
                    // 如果当前路径已经存在于store的defaultOpends里面了，那么不需要dispatch再改变了,只有初次从外面进来的时候才例外
                    if (this.defaultOpeneds.includes(this.ruleList[i].path)) {
                        break;
                    }
                    const defaultOpeneds = [this.ruleList[i].path]
                    this.$store.dispatch('sidebar/setDefaultOpeneds', defaultOpeneds)
                    break;
                }
            }
        },

        handleSelect (key, keyPath) {
            console.log(key, keyPath)
            // this.defaultActive = key
            // console.log(this.defaultActive)
            // let defaultOpeneds = this.defaultOpeneds
            // if (!defaultOpeneds.includes(keyPath[0])) {
            //     console.log('xxxxxxxxxx')
            //     defaultOpeneds = [keyPath[0]]
            // }
            // console.log(defaultOpeneds)
            // this.$store.dispatch('sidebar/setDefaultOpeneds', defaultOpeneds)
            // // const res = key.charAt(0)
            // // this.defaultOpeneds = [`${res}`];
        },
        handleOpen (key, keyPath) {
            console.log(key, keyPath)
            // if (!this.defaultOpeneds.includes(key)) {
            //     this.defaultOpeneds.push(key)
            // }
            const defaultOpeneds = [`${key}`];
            console.log(defaultOpeneds)
            this.$store.dispatch('sidebar/setDefaultOpeneds', defaultOpeneds)
        },

        linkTo (item) {
            if (item.title == '超级会员' || item.title == '数据报表' || item.title == '子账号管理' || item.title == '角色权限设置' || item.title == '优惠券折扣') {
                this.$message.success('敬请期待')
                return
            }
            this.$router.push({ path: item.path });
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//     width: 200px;
//     min-height: 400px;
//   }
.side-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    // width: 100px;
    // width: 200px;
    // background-color: #304156;
    z-index: 9;
    // overflow: hidden !important;
    // width: $sideBarWidth;
    
    .el-menu {
        // width: calc(#{$sideBarWidth} - 20px);
        height: 100%;
    }
    &.openSidebar {
        .el-menu {
            width: calc(#{$sideBarWidth} - 20px);
        }
    }

    // /deep/.el-menu .el-menu-item:hover{
    //     outline: 0 !important;
    //     color: #2E95FB !important;
    //     // background: linear-gradient(270deg, #30B08F 0%, #30B08F 100%)!important;
    //     // background: #30B08F;
    // }
    // /deep/.el-menu .el-menu-item.is-active {
    //     color: #2E95FB !important;
    //     // background: linear-gradient(270deg, #F2F7FC 0%, #30B08F 100%)!important;
    // }
    // /deep/ .el-submenu .el-submenu__title:hover {
    //     color: #2E95FB !important;
    //     background: linear-gradient(270deg, #30B08F 0%, #30B08F 100%)!important;
    //     // background: #30B08F;
    // }

}

.main-title-wrap {
    &.hideSidebar {
        text-align: center;
    }
    &.active {
        i,
        span {
            // color: #20CEAB;
        }
    }
}

// .el-submenu .el-menu-item {
//     background-color: green !important;
// }
.inner-title {
    font-size: 12px;
    // color: #ccc;
    // background-color: red;
}
.el-submenu [class^="el-icon-"] {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    // text-align: center;
    font-size: 18px;
    color: #fff;
}

.el-menu-item [class^="el-icon-"] {
    // vertical-align: middle;
    // margin-right: 5px;
    // width: 24px;
    // text-align: center;
    // font-size: 18px;
    color: #fff;
}
.el-submenu .el-menu-item.inner-title {
    // height: 50px;
    // line-height: 50px;
    // padding: 0 45px;
    min-width: 100% !important;
    background-color: #1f2d3d !important;
}


.el-submenu .el-menu-item {
    // overflow: hidden;
    // width: calc(#{$sideBarWidth} - 20px) !important; 
    background-color: transparent !important;
}
.el-submenu__title:hover {
    background-color: red !important;
}
</style>