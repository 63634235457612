// const state = {
//   defaultOpeneds: []
// }

const getDefaultState = () => {
    return {
        defaultOpeneds: []
    }
}

const state = getDefaultState()

const mutations = {
    SET_DEFAULT_OPENEDS: (state, defaultOpeneds) => {
        // console.log(defaultOpeneds)
        state.defaultOpeneds = defaultOpeneds
    },
}

const actions = {
    setDefaultOpeneds ({ commit }, defaultOpeneds) {
        commit('SET_DEFAULT_OPENEDS', defaultOpeneds)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
