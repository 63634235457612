<template>
    <div v-if="show" class="modal-box">
        <i v-if="closable" @click="handleCloesePopup" class="el-icon-close"></i>
        <slot />
    </div>
</template>

<script>
export default {

    props: {
        value: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            show: this.value,
        }
    },

    watch: {
        value (val) {
            this.show = val
        },
        show (val) {
            this.$emit('input', val)
        }
    },

    methods: {

        handleCloesePopup () {
            this.show = false
            // this.$emit('close')
            let query = this.$route.query
            console.log(query)
            if (query.log_type) {
                query.log_type = ''
            }
            query = JSON.parse(JSON.stringify(query))
            console.log(query)
            this.$router.push({
                path: this.$route.path, query: {
                    ...query
                }
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.modal-box {
    box-sizing: border-box;
    padding: 40px 30px;
    background: white;
    width: 450px;
    // height: 422px;
    border-radius: 4px;
    font-size: 14px;
    i {
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 10px;
        color: #bebebe;
        font-size: 17px;
    }
    i:hover {
        cursor: pointer;
        color: #ff7f20;
    }

    .main {
        box-sizing: border-box;
        padding: 0 20px 30px;
    }
}
</style>