import Vue from 'vue'
import request from '@/common/request'
import { Message } from 'element-ui'

const fetch = (urlStr, data) => {
    if (!urlStr) {
        Message.error('找不到此接口')
        return Promise.reject()
    }
    let urlArr = urlStr.split(', ')
    let url = urlArr[0]
    let method = urlArr[1] || 'post'
    return new Promise((resolve, reject) => {
        return request({
            url: url,
            method: method,
            params: method == 'get' ? data : '',
            data: method == 'post' ? data : ''
        }).then(res => {
            // console.log('aaaaaaaaaaaaaa', res)
            resolve(res)
        }).catch(err => {
            // console.log('bbbbbbbbbbbbbb', err)
            reject(err)
        })
    })
}

Vue.prototype.$fetch = fetch

// Vue.use(popup)
// export default fetch