<template>
    <div 
        class="coupon" 
        :class="{grey: item.surplus_time==='已过期' && item.status != 2}" 
    >
        <div>
            <div class="brick" v-if="item.surplus_time !== '已过期' && item.surplus_time">{{item.surplus_time}}</div>
            <div class="discount-money">￥{{item.discount_money}}</div>
            <div class="full-discount">[满{{item.full_money}}减{{item.discount_money}}]</div>
            <div class="available-time" :class="{'grey': item.status != 2 && item.surplus_time == '已过期'}">有效期至 {{item.endtime | parseTime("{y}-{m}-{d}")}}</div>
            <div class="bottom"> 
                <span>{{ item.category_name }}</span>
                <!-- <span>{{ item.discount_type_name }}</span> -->
                <span v-if="item.status == 2" class="status">已使用</span>
                <el-button v-if="item.status != 2 && item.surplus_time != '已过期' && !checked" @click="useCoupon(item)">立即使用</el-button>
                <span v-if="item.status != 2 &&  item.surplus_time == '已过期'" class="status">已过期</span>
            </div>
        </div>

    </div>

</template>

<script>

export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        checked: {
            type: [Boolean, String],
            default: false
        }
    },
    data () {
        return {
        }
    },
    created () {
        
    },

    methods: {
        useCoupon(item) {
            console.log(item)
            this.$emit('use', item)
        }
    },
};
</script>

<style lang="less" scoped>
.coupon {
    position: relative;
    box-sizing: border-box;
    padding-top: 40px;
    margin: 10px;
    width: 212px;
    height: 186px;
    // width: calc(212px * 0.5);
    // height: calc(186px * 0.5);
    font-size: 14px;
    // font-size: calc(14px * 0.75);
    font-weight: 400;
    background: url("@/assets/images/picture/CouponActive.png");
    &.grey {
        background: url("@/assets/images/picture/Coupon12.png");
    }
    
    .brick {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 80px;
        height: 22px;
        line-height: 22px;
        background: #ffd200;
        border-radius: 0px 0px 4px 4px;
        color: #ff6600;
        text-align: center;
        margin-bottom: 13px;
        
    }
    .discount-money {
        height: 29px;
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
        line-height: 29px;
        text-align: center; /*让文字水平居中*/

        margin-bottom: 14px;
    }
    .full-discount {
        text-align: center;
        height: 13px;
        color: #ffffff;
        line-height: 13px;

        margin-bottom: 11px;
    }
    
    .available-time {
        height: 9px;
        color: #ffffff;
        line-height: 9px;
        text-align: center;
        margin-bottom: 10px;
        &.grey {
            color: #a3a3a3;
        }
    }

    .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        height: 60px;
        span {
            color: #252525;
            &.status {
            }
        }
        
        .el-button {
            width: 80px;
            height: 26px;
            border: 1px solid #ff6f00;
            border-radius: 13px;

            
            color: #ff6f00;

            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background: #ff6f00;
                color: #ffffff;
            }
        }
    }
}
</style>