// instance.js 
import Vue from 'vue'
import { omit } from "lodash-es"
import SConfirm from '../components/SConfirm.vue'
let instance = null;
Vue.prototype.$sConfirm = (message = '', options = {}) => {

    return new Promise((resolve, reject) => {
        let propsKeys = [];
        let props = {};
        propsKeys.forEach(key => {
            props[key] = options[key]
        })
        props['message'] = message
        const SConfirmConstructor = Vue.extend({
            render (createElement) {
                return createElement(SConfirm, {
                    props: props,
                    attrs: {
                        ...(omit(options, propsKeys)),
                        visible: true
                    },
                    on: {
                        'close' () {
                            document.body.removeChild(instance.$el)
                            instance.$destroy();
                            instance = null;
                            reject()
                        },
                        'handleConfirmButton' () {
                            document.body.removeChild(instance.$el)
                            instance.$destroy();
                            instance = null;
                            resolve()
                        },
                        'handleCancelButton' () {
                            document.body.removeChild(instance.$el)
                            instance.$destroy();
                            instance = null;
                            reject()
                        }
                    }
                })
            }
        })
        if (instance) {
            instance.$destroy();
            instance = null;
        }
        instance = new SConfirmConstructor()
        instance.$mount(document.createElement('div'))
        document.body.appendChild(instance.$el)
    })
    
}