<template>

    <div class="home-center-wrap">
        <Navtop hasLogo />
        <!-- <QuickProcess v-if="hasFastPlace" /> -->
        <!-- <FastPlace v-if="hasFastPlace" /> -->
        <Banner v-if="bannerType" :isFullScreen="isFullScreen" :bannerHeight="bannerHeight" :bannerType="bannerType" />
        <slot />
        <BottomOrder v-if="bottomInfo" :bottom-info="bottomInfo" />
        <Navfoot />
        <!-- <Rightbar v-if="showRightbar" /> -->
    </div>

</template>

<script>
export default {
    name: 'HomeCenterWrap',
    props: {
        bottomInfo: {
            type: Object,
            default: () => {
                return null
            }
        },
        bannerType: {
            type: [String, Number],
            default: ''
        },
        bannerHeight: {
            type: [String, Number],
            default: 500
        },
        // showRightbar: {
        //     type: Boolean,
        //     default: false
        // },
        isFullScreen: {
            type: Boolean,
            default: false
        },
        // hasFastPlace: {
        //     type: Boolean,
        //     default: true
        // }
    },
    data () {
        return {}
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
// .home-center-wrap {
//     width: 1200px;
// }
</style>