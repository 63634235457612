<template>
    <div class="total">
        <div class="pay-content">
            <div class="choose">
                <div class="pay-way">
                    <p>请选择您的付款方式</p>
                    <!-- <button :class="{'active': payType===item.type}" v-for="item of payList" :key="item.type" @click="payType = item.type">
                        <span>{{item.name}}付款</span>
                        <img class="duigou" v-if="payType===item.type" src="@/assets/images/icon/duigou.png">
                    </button> -->
                    <el-radio-group class="radio-box" v-model="payType">
                        
                        <div class="radio-item" v-for="item of payList" :key="item.type">
                            <el-radio :label="item.type">
                                <img :src="require(`@/assets/images/icon/${item.icon}.png`)">
                                {{item.name}}
                            </el-radio>
                        </div>
                        
                    </el-radio-group>
                </div>

                <div class="balance">
                    <p>钱包余额：<span class="high-light">￥{{money}}</span></p>
                    <router-link :to="'/wallet/recharge/?order_price=' + discountPay" target="_blank"><button>钱包充值</button></router-link>
                </div>
            </div>

            <div class="need-pay">
                <p v-if="discountmoney.discount_money">
                    <span class="basic-price">{{ requiredPay }}</span> 
                    优惠后<b>￥{{ discountPay }}</b>元
                </p>
                <p v-else>需付款金额：<b>￥{{ requiredPay }}</b></p>
                <p v-if="!isBukuan">{{ remainHourTips }}</p>
                <!-- <p>师傅会在您付款后尽快预约客户上门服务</p> -->
                <button @click="handlePayBtn">确认付款</button>
            </div>
            
            <!-- <payCoupon 
                v-if="!replenishmentId" 
                :orderInfo="orderInfo"
                @sendDiscountmoney="sendDiscountmoney"
            /> -->
        </div>

        <PayWx v-model="showWxCode" :orderId="orderId" :codeValue="codeValue" :replenishmentId="replenishmentId" @success="paySuccess" />
        <PayWallet v-model="showWallet" :orderId="orderId" :replenishmentId="replenishmentId" :rid="discountmoney.rid" :requiredPay="discountPay" @success="paySuccess" />


    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PayWx from './PayWx.vue';
import PayWallet from "./PayWallet.vue";

export default {
    // inject: ['getDetail'],
    components: {
        PayWx,
        PayWallet,
    },
    props: {
        orderInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        orderId: {
            type: String,
            default: ''
        },
        replenishmentId: {
            type: String,
            default: ''
        },
        startTime: {
            type: [String, Number],
            default: ''
        },
        timeSection: {
            type: [String, Number],
            default: 48
        },
        requiredPay: {
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        discountmoney: {
            discount_money: '',
            rid: ''
        },

        payType: 'wallet',
        payList: [
            { name: '钱包余额', type: 'wallet', icon: 'paywallet' },
            { name: '微信', type: 'wechat', icon: 'paywx' },
            { name: '支付宝', type: 'alipay', icon: 'payali' },
        ],
        showWallet: false,
        showWxCode: false,
        codeValue: '', // 微信二维码
    }),
    computed: {

        discountPay() {
            let money = 0
            if (this.discountmoney.discount_money=='') {
                money = this.requiredPay
            } else {
                money = (+this.requiredPay - +this.discountmoney.discount_money).toFixed(2)
            }
            if (money < 0) {
                return 0
            }
            return money
        },
        // order_id、价格、起始时间、补款信息
        replenish() {
            return this.orderInfo.replenish
        },

        isBukuan() {
            return !!this.replenishmentId
        },

        remainHourTips () {
            if (!this.startTime) return
            const endTimeStamp = this.startTime * 1000 + this.timeSection * 3600 * 1000
            console.log(endTimeStamp)
            const remainPayHours =  Math.floor((endTimeStamp - +new Date()) / (3600 * 1000))
            console.log(remainPayHours)
            let tips = ''
            if (remainPayHours > 0) {
                tips = `请您在${this.timeSection}小时之内尽快付款（当前付款剩余时间${remainPayHours}小时）`
            } else if (remainPayHours == 0) {
                tips = `请您在${this.timeSection}小时之内尽快付款（当前付款剩余时间小于1小时）`
            } else if (remainPayHours < 0) {
                tips = '订单已过期'
            }
            return tips
        },

        ...mapGetters({
            money: 'money'
        })

    },

    mounted () {
        this.$store.dispatch('user/getUserInfo').then(data => {})
    },

    methods: {

        sendDiscountmoney(discountmoney) {
            this.discountmoney = discountmoney
        },

        handlePayBtn () {
            this.$sConfirm('付款后，钱款暂存在平台，待师傅完工无误七天之后才会收到钱款，期间有任何问题我们为您处理', {
                title: '是否确认付款？',
                confirmButtonText: '确认付款',
                cancelButtonText: '我再想想',
                confirmType: 'success'
            }).then(res => {
                if (this.payType == 'wallet') {
                    this.$fetch(this.$api.purse.checkWalletPassword).then(data => {
                        this.showWallet = true
                    }).catch(err => {
                        this.$router.push({path: '/wallet/list/', query: { 
                            change: true 
                        }})
                    })
                } else if (this.payType == 'alipay') {
                    this.aliPay()
                } else if (this.payType == 'wechat') {
                    this.wxPay()
                }
            })
        },
        
        async aliPay () {
            let params = {
                order_id: this.orderId,
                replenishment_id: this.replenishmentId,
                rid: this.discountmoney.rid,
                type: 'alipay',
                method: 'web',
                // rid: '',
                cb_url: location.origin + '/%23/order/pay-success?order_id=' + this.orderId,
            };
            console.log(params)
            let payApi = !this.isBukuan ? this.$fetch(this.$api.order.payOrder, params) : this.$fetch(this.$api.order.orderReplenishment, params)
            payApi.then(data => {
                console.log(data)
                if (data) {
                    let divForm = document.getElementsByTagName('divform')
                    if (divForm.length) {
                        document.body.removeChild(divForm[0])
                    }
                    const div = document.createElement('divform')
                    div.innerHTML = data // res.data就是sb支付宝返回给你的form
                    document.body.appendChild(div)
                    // document.forms[0].setAttribute('target', '_blank') // 加了_blank可能出问题所以我注释了
                    document.getElementById('alipay_submit').submit()
                }
            })
        },

        async wxPay () {
            let params = {
                order_id: this.orderId,
                replenishment_id: this.replenishmentId,
                rid: this.discountmoney.rid,
                type: 'wechat',
                method: 'scan',
                // rid: '',
                cb_url: location.origin + '/%23/order/pay-success?order_id=' + this.orderId,
            };
            console.log(params)
            let payApi = !this.isBukuan ? this.$fetch(this.$api.order.payOrder, params) : this.$fetch(this.$api.order.orderReplenishment, params)
            payApi.then(resp => {
                console.log(resp)
                if (resp.return_code == 'SUCCESS') {
                    this.showWxCode = true
                    this.codeValue = resp.code_url;
                }
            })
        },

        paySuccess () {
            this.$emit("paySuccess")
        },

    }
}
</script>

<style lang="scss" scoped>
.total {
    width: 100%;
    color: #606266;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    // .pay-content {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     align-items: center;
    // }
    .pay-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    .need-pay {
        width: 100%;
        .basic-price {
            color: red;
            text-decoration: line-through;
        }
    }
    button {
        cursor: pointer;
        outline: 0;
    }
    .choose {
        width: 100%;
    }
    .pay-way {
        width: 100%;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-start;
        // align-items: center;
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-start;
        // align-items: flex-start;
        text-align: left;
        margin-top: 10px;
        .radio-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .radio-item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-end;
                box-sizing: border-box;
                padding: 20px 0;
                width: 100%;
                border-bottom: 1px solid #cccccc;
                // &:last-child {
                //     border-bottom: none;
                // }
                // .radio-box.el-radio-group
                //     /deep/
                //     .el-radio__input.is-checked
                //     + .el-radio__label {
                //     color: #20ceab;
                // }
                // .el-radio__label {
                //     display: flex;
                //     flex-direction: row;
                //     justify-content: flex-start;
                //     align-items: center;
                // }
            }
        }
        .radio-box.el-radio-group {
            /deep/
            .el-radio {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                
                .el-radio__label {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 20px;
                    }
                }
            }
        }
        // .radio-box.el-radio-group /deep/ .el-radio {
        //     margin-top: 16px;
        //     border-bottom: 1px solid #333;
        //     // &:last-child {
        //     //     margin-bottom: 20px;
        //     // }
        // }
        // .radio-box.el-radio-group
        //     /deep/
        //     .el-radio
        //     .el-radio__input.is-checked
        //     .el-radio__inner {
        //     border-color: #20ceab;
        //     background: #20ceab;
        // }
        // .radio-box.el-radio-group
        //     /deep/
        //     .el-radio__input.is-checked
        //     + .el-radio__label {
        //     color: #20ceab;
        // }
        p {
            margin-bottom: 10px;
        }
        button {
            border: 1px solid #20ceab;
            border-radius: 5px;
            width: 220px;
            padding: 16px 0;
            background: white;
            margin-right: 20px;
            font-size: 16px;
            position: relative;
            margin-bottom: 20px;
            &.active {
                color:#20ceab;
                font-weight: 700;
            }
            img.duigou {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
    .balance {
        font-size: 14px;
        // margin: 30px 0 15px;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        p {
            color: #333;
        }
        .high-light {
            font-size: 16px;
            color:#20ceab;
            font-weight: 700;
        }
        button {
            background: #ee685d;
            border: none;
            padding: 8px 48px;
            border-radius: 5px;
            color: white;
            // margin-left: 20px;
            margin-top: 10px;
        }
    }
    .need-pay {
        font-size: 12px;
        text-align: right;
        .red {
            color: #ee685d !important;
        }
        p {
            color: #888888;
            // margin: 2px 0;
            line-height: 2;
            b {
                color: #ee685d;
            }
        }
        p:nth-child(1) {
            // color: #888888;
            color: #333333;
            font-size: 14px;
            b {
                font-size: 20px;
            }
        }
        button {
            background: #20ceab;
            border: none;
            padding: 10px 30px;
            border-radius: 5px;
            color: white;
            margin: 8px 0;
        }
    }
}
</style>