import { getToken, setToken, removeToken } from '@/common/auth'

export function toH5 (path) {
    (function (ua) {
        var __im = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(ua);
        var __iw = /Windows NT/.test(ua);
        var __it = 'ontouchstart' in document.documentElement && !__iw;
        var __ia = /PconeAPP/.test(ua);
        var __is = /(iPad|iPhone)/.test(ua) && /Safari/.test(ua) && !/CriOS/.test(ua);
        var __me = __is ? 'assign' : 'replace';
        __im = __im || __it || __ia;
        let isPrivacy = path.indexOf('/protocol/settle') > -1 || path.indexOf('/protocol/privacy') > -1
        let isWxScan = path.indexOf('/checkWx') > -1
        let isLoadMaster = path.indexOf('/loadMaster') > -1
        if (__im && !isPrivacy && !isWxScan && !isLoadMaster) {
            location.href = 'https://m.daijige.com/'
        }
    }(navigator.userAgent));
}

export function isValid (path) {
    const validList = [
        // '/account/child',
        // '/account/roles',
        '/login',
        '/tool/report',
        '/order/my-evaluate',
        '/order/list',
        '/order/detail',
        // '/order/problem',
        '/order/pay-success',
        '/place',
        '/place/pay',
        '/place/success',
        '/protocol/privacy',
        '/protocol/settle',

        '/order/back',
        // '/rp/complaint',
        '/order/rplist',
        '/order/rpdetail',


        '/tool/goods-image',
        '/tool/goods-template',
        '/tool/master-info',
        '/tool/complete-image',
        '/tool/my-master',


        '/user/safe',
        '/user/base',
        '/msg/message',

        '/wallet/list',
        '/wallet/success',
        '/wallet/coupon',
        '/wallet/recharge',

        '/about',
        '/checkWx',
        '/loadMaster',
        '/contactUs',
        '/master',
        '/help',
        '/settle',
        '/news',
        '/newsin',
        '/service',
        '/activity',
        '/vip',
    ]
    if (path === '/') {
        return true
    } else {
        for (let i = 0, len = validList.length; i < len; i++) {
            if (path.indexOf(validList[i]) > -1) {
                return true
            }
        }
        return false
    }
}


export function isWhitePath (path) {
    const whiteList = [
        '/master',
        '/service',
        '/activity',
        '/settle',
        '/vip',
        '/news',
        '/newsin',
        '/help',
        '/about',
        '/contactUs',

        '/checkWx',
        '/loadMaster',
        '/login',
        '/protocol/privacy',
        '/protocol/settle',
    ]
    if (path === '/') {
        return true
    } else {
        for (let i = 0, len = whiteList.length; i < len; i++) {
            if (path.indexOf(whiteList[i]) > -1) {
                return true
            }
        }
        return false
    }
}


export function redirectSeo (path, to) {
    
    const baseUrlSEO = 'https://www.daijige.com'
    const seoList = [
        // {
        // 	whitePath: '/',
        // 	seoPath: ''
        // },
        {
            whitePath: '/master', // 师傅资源
            seoPath: '/master/index.html'
        },
        {
            whitePath: '/service', // 服务保障
            seoPath: '/service/index.html'
        },
        {
            whitePath: '/activity', // 优惠活动
            seoPath: '/activity/index.html'
        },
        {
            whitePath: '/settle', // 师傅入驻
            seoPath: '/mastersettle/index.html'
        },
        {
            whitePath: '/news', // 资讯中心
            seoPath: '/news/index.html'
        },
        {
            whitePath: '/help', // 帮助中心
            seoPath: '/helpcenter/index.html'
        },
        {
            whitePath: '/about', // 关于我们
            seoPath: '/about/index.html'
        },
    ]
    // 如果当前主机是开发环境，则不跳转
    let currentPath = window.document.location.href
    if (currentPath.indexOf('//localhost:') > -1) {
        return
    }
    // 如果有token，则不跳转至www.daijige.com
    if (getToken()) return
    // 非开发环境且无tonken(未登录)的情况
    for (let i = 0, len = seoList.length; i < len; i++) {
        if (path.indexOf('/news/') > -1) {
            if (!to.query.newsType) {
                location.href = baseUrlSEO + '/news/index.html'
            } else {
                location.href = baseUrlSEO + `/news/list-${to.query.newsType}.html`
            }
            return
        }
        // 如果要跳转的页面是在白名单里的
        if (path.indexOf(seoList[i].whitePath) > -1) {
            location.href = baseUrlSEO + seoList[i].seoPath
            break;
        }
    }

}