<template>
    <div class="img-uploader">
        <el-upload 
            action="https://demo.daijige.com/service-info/upload.html" 
            list-type="picture-card" 
            :file-list="value" 
            :limit='limit' 
            :before-upload="beforeUpload" 
            :on-exceed="OnExceed" 
            :on-remove="(file, fileList) => {handleRemove(file, fileList)}" 
            :on-success="(response, file, fileList) => {handleSuccess(response, file, fileList)}"
        >
            <i class="el-icon-plus"></i>
        </el-upload>
    </div>
</template>

<script>
export default {

    props: {
        limit: {
            type: Number,
            default: 1
        },
        value: {
            type: Array,
            default: () => {
                return []
            }
        },
    },

    data: () => ({
    }),

    methods: {

        OnExceed (file, fileList) {
            this.$message.error(`图片不得大于${this.limit}张`);
        },

        beforeUpload (file) {
            // return true
            let types = ['image/jpeg', 'image/jpg', 'image/png'];
            const isImage = types.includes(file.type);
            // const isLt5M = file.size / 1024 / 1024 < 15;
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isImage) {
                this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
            }
            if (!isLt5M) {
                // this.$message.error('上传图片大小不能超过 15MB!');
                this.$message.error('上传图片大小不能超过5MB!');
            }
            return isImage && isLt5M;
        },

        handleSuccess (response, file, fileList) {
            console.log(response)
            console.log(fileList)
            const imgList = fileList.map(v => {
                if (v.response) {
                    v.url = this.$constant.cdn + v.response.data.url
                    v.upload_url = v.response.data.url
                }
                return v
            })
            console.log(imgList)
            // this.$emit('changeImgList', imgList)
            this.$emit('input', imgList)
            console.log('!!!!!!')
            this.$emit('success')
        },

        handleRemove (file, fileList) {
            // this.$emit('changeImgList', fileList)
            this.$emit('input', fileList)
        },

    }

}
</script>

<style lang="scss" scoped>
.img-uploader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
// .fade-enter-active,
// .fade-leave-active {
//     transition: opacity 0.5s;
// }
// .fade-enter,
// .fade-leave-to {
//     opacity: 0;
// }
// .modal-box {
//     overflow: auto;
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.5);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 100;
//     > div {
//         background: white;
//         width: 31rem;
//         border-radius: 4px;
//         font-size: 14px;
//         text-align: center;
//         .title {
//             text-align: right;
//             padding: 15px 10px;
//             i {
//                 color: #bebebe;
//                 font-size: 20px;
//             }
//             i:hover {
//                 cursor: pointer;
//                 color: #45e7c7;
//             }
//         }
//         .main {
//             padding: 15px 50px;
//             line-height: 24px;
//             .btn-box {
//                 display: flex;
//                 justify-content: center;
//                 margin: 40px 0 25px;
//                 .btn-wrap {
//                     flex: 1 1 50%;
//                     box-sizing: border-box;
//                     padding: 0 10px;
//                 }
//                 button {
//                     width: 100%;
//                     border: none;
//                     outline: 0;
//                     background: white;
//                     font-size: 16px;
//                     padding: 0.7rem 2.8rem;
//                     border-radius: 5px;
//                 }
//                 button.active {
//                     background: #20ceab;
//                     color: white;
//                 }
//                 button.active.red-confirm {
//                     background: #ee685d;
//                 }
//                 button.red-button {
//                     background: #ee685d;
//                     color: white;
//                 }
//             }
//         }
//     }
// }
</style>