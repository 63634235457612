<template>
    <el-cascader
        size="large"
        :options="cityList"
        :props="props"
        @change="handleChange"
        style="width: 300px;"
        clearable
    ></el-cascader>

</template>

<script>

export default {
    name: 'AddressCascader',
    props: {
        checkStrictly: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            cityList: [],
            props: {
                expandTrigger: 'click', 
                value: 'id', 
                label: 'name',
                lazy: true,
                checkStrictly: this.checkStrictly,
                lazyLoad: (async (node, resolve) => {
                    const params = {
                        level: node.level + 1,
                        pid: node.value,
                    }
                    let data = await this.$fetch(this.$api.serviceInfo.city, params)
                    // 如果当前是2级node，那么3级node的leaf全赋值为true
                    const nextNodeLevel = node.level + 1
                    const isLeaf = nextNodeLevel == 3
                    const nodes = data.map(v => {
                        v.leaf = isLeaf
                        return v
                    })
                    resolve(nodes);
                })
            }
        }
    },
    // data: () => ({
        
    // }),
    created () {
    },
    methods: {

        handleChange(value) {
            console.log(value);
            this.$emit('change', value)
        },

    }
}
</script>

<style lang="scss" scoped>
</style>