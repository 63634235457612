// export { parseTime, formatTime, getSortClass } from '@/utils'
// import func from '@/common/func'
// export const statusCN = func.statusCN
// export { parseTime } from '@/common/func'

export function orderStatusCN (status) {
    const map = new Map([
        ['100', '待报价'],
        ['200', '待雇佣'],
        ['300', '待付款'],
        ['400', '待预约'],
        ['500', '待提货'],
        ['600', '待打卡'],
        ['700', '待验货'],
        ['800', '待完工'],
        ['900', '待验收'],
        ['1000', '待评价'],
        ['1001', '待评价'],
        ['1002', '评价完成'],
        ['1100', '已完结'],
        ['1200', '交易关闭'],
        ['1201', '交易关闭'],
        ['1300', '师傅维权'],
        ['1301', '维权完成'],
        ['1400', '商家投诉'],
        ['1401', '投诉完成']
    ])
    return map.get('' + status)
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime (time, cFormat) {
    // 当没有登录时间时默认返回'0'
    if (time === '0' || time === 0) {
        return '-'
    }
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string')) {
            if ((/^[0-9]+$/.test(time))) {
                // support "1548221490638"
                time = parseInt(time)
            } else {
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(new RegExp(/-/gm), '/')
            }
        }

        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        return value.toString().padStart(2, '0')
    })
    return time_str
}
