<template>
    <div class="user-center-wrap">
        <Navtop :showNavItem="false" :fixed="true" :hasService="hasService" :hasSidebar="hasSidebar" />

        <div class="content-wrap" :class="classObj">
            <Sidebar v-if="hasSidebar" />

            <div class="page-info" :style="{'backgroundColor': bg}">
                <!-- <PageTitle v-if="title" :title="title" :hasPlaceBtn="hasPlaceBtn" :hasService="hasService" /> -->
                
                <div class="detail-box">
                    <slot />
                    
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
// import PageTitle from './PageTitle'
// import Sidebar from './Sidebar'

export default {
    components: {
        // PageTitle,
        // Sidebar
    },
    props: {
        bg: {
            type: String,
            default: '#ffffff'
        },
        title: {
            type: String,
            default: ''
        },
        hasSidebar: {
            type: Boolean,
            default: true
        },
        hasPlaceBtn: {
            type: Boolean,
            default: true
        },
        hasService: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        ...mapState({
            sidebar: state => state.app.sidebar,
            // device: state => state.app.device,
            // showSettings: state => state.settings.showSettings,
            // needTagsView: state => state.settings.tagsView,
            // fixedHeader: state => state.settings.fixedHeader
        }),
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                // openSidebar: this.sidebar.opened,
                // withoutAnimation: this.sidebar.withoutAnimation,
                // mobile: this.device === 'mobile',
                noSidebar: !this.hasSidebar,
                pl: this.hasSidebar
            }
        }
    }

}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.user-center-wrap {
    // width: 1920px;
    // background: #f5f6fa;
    // overflow: overlay;
    // background-color: #f0f2f5;
}
.content-wrap {
    // width: 1920px;
    // min-width: 1440px;
    width: 100%;
    min-height: 800px;
    box-sizing: border-box;
    // padding: 1.5rem 30px 0;
    // padding: 1.5rem 0 0;
    padding: 0;
    // padding-left: 220px !important;
    // padding-left: $sideBarWidth !important;
    // padding-left: 0;
    // padding-top: 90px;
    // padding-top: 80px;
    // padding-top: 60px;
    padding-top: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    &.pl {
        padding-left: $sideBarWidth !important;
    }
    &.hideSidebar {
        padding-left: 80px !important;
    }
    &.noSidebar {
        width: 1200px;
        margin: 0 auto;
        // padding-left: 10rem !important;
        // padding-right: 20rem !important;
    }
    .side-nav {
        // flex: 0 0 200px;
        // margin-right: 30px;
        // border-radius: 10px;
        // background: #fff;
        // overflow: hidden;
        // height: inherit;
    }
    .page-info {
        width: 100%;
        // flex: 1;
        // padding: 10px 0;
        // margin: 0 30px 100px 0;
        margin: 24px 30px 30px 0;
        // background: #fff;
        // border-radius: 10px;
        .detail-box {
            box-sizing: border-box;
            width: 100%;
            // min-height: 100vh;
            // margin: 1rem;
            padding: 1rem;
            // padding: 0rem 0rem 0rem .5rem;
            // min-height: 600px;
            // overflow: auto;
            // position:relative;
            // width: 100%;
            height: 100%;
        }
    }
}
</style>