<template>
    <div class="pay-coupon">
        <div class="toggle">
            <span class="mr10 plus" @click="toggleCouponContent">{{ isShowContent ? '-' : '+' }}</span>
            <span @click="toggleCouponContent">{{ coupon ? '切换' : '选择' }}优惠券</span>
            <span v-if="coupon" class="ml20" @click="cancelCoupon">不使用优惠券</span>
        </div>

        <CouponItem v-if="coupon" :item="coupon" checked />

        <ModalBox width="" title="优惠券" v-show="isShowContent" @cancel="isShowContent=false">
            <CouponList @use="useCoupon" />
        </ModalBox>
    </div>
</template>

<script>
export default {
    props: {
        orderInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data: () => ({
        isShowContent: false,
        coupon: null
    }),

    methods: {

        cancelCoupon() {
            this.coupon = null
            this.$emit('sendDiscountmoney', {
                discount_money: '',
                rid: ''
            })
        },

        // 使用优惠券
        useCoupon(item) {
            console.log(item)
            // item.discount_money = 13
            this.coupon = item
            this.$emit('sendDiscountmoney', item)
            this.isShowContent = false
        },

        // 打开/关闭优惠券框
        toggleCouponContent() {
            this.isShowContent = !this.isShowContent
        },

    }
}
</script>

<style lang="scss" scoped>
.pay-coupon {
    width: 100%;
    margin: 20px 0;
    font-weight: 100;
    h3 {
        font-weight: normal;
        margin: 5px 0;
    }
    .toggle {
        padding-left: 10px;
        cursor: pointer;
        margin-bottom: 20px;
        .plus {
            color: white;
            padding: 0 5px;
            background: #20ceab;
        }
    }

}
</style>