<template>

    <!-- <el-dialog class="wx-pay-dialog" title="微信付款二维码" :visible.sync="value" :before-close="closeWxCode" center width="20%"> -->
    <el-dialog class="wx-pay-dialog" :modal="true" :modal-append-to-body="false" title="微信付款二维码" :visible="value" :before-close="closeWxCode" center width="20%">
        <vue-qr class="bicode" :logoScale="20" :margin="0" :dotScale="1" :text="codeValue"></vue-qr>
    </el-dialog>

</template>

<script>
import VueQr from 'vue-qr'

export default {
    components: {
        VueQr
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        codeValue: {
            type: String,
            default: ''
        },
        replenishmentId: {
            type: String,
            default: ''
        },
        orderId: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        timer: null,
    }),
    
    computed: {

        // isBukuan () {
        //     return this.$route.query.isBukuan
        // },

        isBukuan() {
            return !!this.replenishmentId
        },

    },

    watch: {
        value(val) {
            if (val) {
                this.checkPayStatus()
                this.timer = setInterval(() => {
                    this.checkPayStatus()
                }, 5000)
            }
        }
    },

    beforeDestroy () {
        clearInterval(this.timer);
        this.timer = null;
    },

    methods: {

        // 确认微信付款/补款是否成功
        async checkPayStatus () {
            let data
            if (!this.isBukuan) {
                // data = this.$fetch(this.$api.payres.checkPayStatus, { order_id: this.$route.query.id })
                data = this.$fetch(this.$api.payres.checkPayStatus, { order_id: this.orderId })
            } else {
                // 跟h5不同，这里不刷新，在orderInfo的orderReplenishment里获取
                // data = this.$fetch(this.$api.payres.checkPayStatusReplenishment, { order_replenishment_id: this.$route.query.replenishment_id })
                data = this.$fetch(this.$api.payres.checkPayStatusReplenishment, { order_replenishment_id: this.replenishmentId })
            }
            data.then(data => {
                console.log(data)
                if (data.is_pay) {
                    this.closeWxCode()
                    this.$emit('success')
                }
            })
        },

        closeWxCode () {
            this.$emit('input', false)
            clearInterval(this.timer);
        },

    }
}
</script>

<style lang="scss" scoped>
.el-dialog__wrapper.wx-pay-dialog /deep/ .el-dialog__body {
    text-align: center;
}
</style>