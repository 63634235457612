<template>
    <el-row class="category" :gutter="gutter">

        <el-col :span="span">
            <el-select clearable :disabled="disabledOneCateg" v-model="category_id_one" @change="selOneId" placeholder="商品一级类目">
                <el-option v-for="(one, index) in oneCategList" :key="index" :label="one.name" :value="one.id" />
            </el-select>
        </el-col>

        <el-col :span="span">
            <el-select clearable :disabled="disabledTwoCateg" v-model="category_id_two" @change="selTwoId" placeholder="商品二级类目">
                <el-option v-for="(one, index) in twoCategList" :key="index" :label="one.name" :value="one.id" />
            </el-select>
        </el-col>

        <el-col :span="span">
            <el-select clearable :disabled="disabledThreeCateg" v-model="category_id" @change="selThreeId" placeholder="商品三级类目">
                <el-option v-for="(one, index) in threeCategList" :key="index" :label="one.name" :value="one.id" />
            </el-select>
        </el-col>

    </el-row>
</template>

<script>

export default {

    props: {
        gutter: {
            type: Number,
            default: 24
        },
        disabledOneCateg: {
            type: Boolean,
            default: false
        },
        disabledTwoCateg: {
            type: Boolean,
            default: false
        },
        disabledThreeCateg: {
            type: Boolean,
            default: false
        },
        serverId: {
            type: [Number, String],
            default: ''
        },
        offerType: {
            type: [Number, String],
            default: ''
        },
    },

    data: () => ({
        category_id_one: '',
        category_id_two: '',
        category_id: '',

        oneCategList: [],
        twoCategList: [],
        threeCategList: [],
    }),

    computed: {
        span() {
            // console.log(Math.ceil(this.gutter / 3))
            return Math.ceil(this.gutter / 3)
        },
        category_name_one () {
            for (let i = 0, len = this.oneCategList.length; i < len; i++) {
                const item = this.oneCategList[i]
                if (item.id === this.category_id_one) {
                    return item.name
                }
            }
            return ''
        },
        category_name_two () {
            for (let i = 0, len = this.twoCategList.length; i < len; i++) {
                const item = this.twoCategList[i]
                if (item.id === this.category_id_two) {
                    return item.name
                }
            }
            return ''
        },
        category_name () {
            for (let i = 0, len = this.threeCategList.length; i < len; i++) {
                const item = this.threeCategList[i]
                if (item.id === this.category_id) {
                    return item.name
                }
            }
            return ''
        },
        // attr () {
        //     for (let i = 0, len = this.threeCategList.length; i < len; i++) {
        //         const item = this.threeCategList[i]
        //         if (item.id === this.category_id) {
        //             return item.attr
        //         }
        //     }
        //     return []
        // },
    },

    watch: {
        serverId(val) {
            console.log('serverId', val)
        },
        offerType(val) {
            console.log('offerType', val)
        },

        category_id_one (val) {
            console.log('---', val)
            this.$emit('change')
            if (val) {
                this.getTwoCateg()
            }
        },
        category_id_two (val) {
            this.$emit('change')
            if (val) {
                this.getThreeCateg()
            }
        },
        category_id (val) {
            this.$emit('change')
            // if (val) {}
        },
        // attr (val) {
        //     // if (this.hasGoodModelFlag === true) {
        //     //     return
        //     // }
        //     this.$emit('changeAttr', val)
        // }
    },

    created () {
        this.getOneCateg();
    },

    methods: {

        selOneId () {
            this.category_id_two = '';
            this.category_id = '';
            this.twoCategList = []
            this.threeCategList = []
        },

        selTwoId () {
            this.category_id = '';
            this.threeCategList = [];
        },

        selThreeId (category_id) {
            console.log(category_id)
            // if (this.hasGoodModelFlag === true) {
            //     this.hasGoodModelFlag = false
            // }
            for (let i=0; i<this.threeCategList.length; i++) {
                if (this.threeCategList[i].id == category_id) {
                    console.log(this.threeCategList[i].attr)
                    this.$emit('changeAttr', this.threeCategList[i].attr)
                    break;
                }
            }
        },

        async getOneCateg () {
            let data = await this.$fetch(this.$api.category.firstcategorys)
            this.oneCategList = data;
        },

        async getTwoCateg () {
            const params = {
                pid: this.category_id_one,
                servertype_id: this.serverId,
                offer_type: this.offerType,
            }
            let data = await this.$fetch(this.$api.category.nextcategorys, params)
            console.log('2222222222222222222', data)
            this.twoCategList = data;
        },

        async getThreeCateg () {
            const params = {
                pid: this.category_id_two,
                servertype_id: this.serverId,
                offer_type: this.offerType,
            }
            let data = await this.$fetch(this.$api.category.nextcategorys, params)
            console.log('333333333333333333', data)
            this.threeCategList = data;
        },

    }
};
</script>

<style lang="scss" scoped>
.category /deep/ .el-select {
    width: 100%;
}
</style>