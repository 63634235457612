<template>
    <div class="pwd-options">
        <!-- <u-icon class="u-icon" :name="'eye-off'" size="40" color="#bbbbbb" @tap="isPwdShow=!isPwdShow"></u-icon> -->
        <div class="forget-pwd" :class="{'active-forgotten': codeSeconds, 'mobile-available': isMobileAvailable, 'disabled': codeSeconds}" @click="handleSendCode">
            <span>{{codeSeconds ? `重新获取(${codeSeconds}s)` : '获取验证码'}}</span>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        event: {
            type: String,
            default: '',
        },
        mobile: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            codeSeconds: 0,
        }
    },
    computed: {
        isMobileAvailable () {
            // return this.$u.test.mobile(this.mobile)
            if (!(this.$func.validMobile(this.mobile))) {
                return false
            }
            return true
        },
    },
    destroyed () {
        // clearInterval(this.verifTimer);
    },
    methods: {

        async handleSendCode () {
            if (!this.isMobileAvailable) {
                this.$message.error('手机号格式不对');
                return
            }
            if (this.codeSeconds) {
                this.$message.error('您发送验证太频繁了')
                return
            }
            const params = {
                mobile: this.mobile,
                event: this.event,
            }
            console.log(params)
            let data = await this.$fetch(this.$api.user.send, params)
            this.codeSeconds = 60;
            // this.send.show = true;
            let verifTimer = setInterval(() => {
                this.codeSeconds--;
                if (this.codeSeconds < 1) {
                    // this.send.show = false;
                    clearInterval(verifTimer);
                }
            }, 1000)
        }

    }
}
</script>

<style lang="scss" scoped>
.pwd-options {
    position: absolute;
    right: 30px;
    top: 5px;

    display: flex;
    align-items: center;
    width: 90px;
    height: 30px;
    // .u-icon {
    // 	position: absolute;
    // 	right: 200rpx;
    // }

    .forget-pwd {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #c0c0c0;
        box-sizing: border-box;
        // padding: 0px 15px;
        border-radius: 4px;
        border: 1px solid #c0c0c0;

        &.active-forgotten {
            color: #20ceab;
            border: 1px solid #20ceab;
        }
        &.mobile-available {
            color: #20ceab;
            border: 1px solid #20ceab;
        }
        &.disabled {
            color: #c0c0c0;
            border: 1px solid #c0c0c0;
        }
    }
}
</style>