<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <!-- <router-view></router-view> -->
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
#app {
    overflow-x: scroll;
    /* overflow-y: scroll; */
    /* overflow-y: unset; */
    background: #f5f6fa;
    /* font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px; */
}
</style>
