<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}" :style="'background: ' + backgroundColor +';'">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/place">
        <img v-if="logoSimple" :src="logoSimple" class="sidebar-logo">
        <!-- <h1 v-else class="sidebar-title">{{ title }} </h1> -->
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/place">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <!-- <h1 class="sidebar-title">{{ title }} </h1> -->
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    },
    backgroundColor: {
      type: String,
      default: '#304156'
    }
  },
  data() {
    return {
      title: '呆鸡哥',
      // logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
      // logo: '@required(@/src/assets/images/picture)'
      logo: require(`@/assets/images/picture/LOGO@2x-2.png`),
      logoSimple: require(`@/assets/images/picture/avatar.png`),
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  // width: 100%;
  width: calc(100% - 1px);
  height: 56px;
  line-height: 50px;
  // background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      // width: 32px;
      // height: 32px;
      width: 95px;
      // height: 46px;
      vertical-align: middle;
      margin-right: 40px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      width: 32px;
      height: 32px;
      margin-right: 0px;
    }
  }
}
</style>
