export function checkBaiduPage (path) {
	// console.log(path)
	const baiduPageList = [
		'/checkWx/',
		'/login/',
		// '/', 
		'/master/',
		'/service/',
		'/activity/',
		'/settle/',
		'/vip/',
		'/news/',
		'/newsin/',
		// '/help/',
		'/about/',
		'/contactUs/',
		'/protocol/privacy/',
		'/protocol/settle/',
	]
	if (path === '/') {
		return true
	} else {
		for (let i = 0, len = baiduPageList.length; i < len; i++) {
			// console.log(baiduPageList[i])
			if (path.indexOf(baiduPageList[i]) > -1) {
				// console.log('22222222222')
				return true
			}
		}
		// console.log('3333333333333')
		return false
	}
}

export function removeBaiduBridge () {
	// let bridge = document.getElementById('newBridge')
	let bridge = document.getElementById('aff-im-root')
	// console.log(bridge)
	if (bridge) {
		bridge.parentNode.removeChild(bridge)
	}
}
export function initBaiduBridge () {
	var _hmt = _hmt || [];
	(function () {
		var hm = document.createElement("script");
		hm.src = "https://hm.baidu.com/hm.js?e0c0aaf7958262de3dbf91aaba0beed4";
		var s = document.getElementsByTagName("script")[0];
		s.parentNode.insertBefore(hm, s);
	})();
}
