import Cookies from 'js-cookie'

const TokenKey = 'daijige-pc-token'
const expires_time = new Date(new Date().getTime() + 7 * 24 * (60 * 60 * 1000))

export function getToken () {
    return Cookies.get(TokenKey)
}

export function setToken (token) {
    return Cookies.set(TokenKey, token, { expires: expires_time })
}

export function removeToken () {
    return Cookies.remove(TokenKey)
}
