
// const CONFIG = {
//     curEnv: 'dev',
//     env: {
//         dev: {
//             site: 'http://tpc.daijige.com/',
//             api: 'http://ipc.daijige.com/'
//             // api: 'https://demo.daijige.com/',
//             // api: 'https://zpc.daijige.com/',
//             // api: 'http://www.mctest.com/',
//             // api: 'http://frontend.test.com/',
//         },
//         prod: {
//             site: 'https://user.daijige.com/',
//             api: 'https://demo.daijige.com/'
//         }
//     }

// }
// export default CONFIG


const CONFIG = {
	// 开发环境配置
	development: {
		// baseUrl: 'http://st.ifrontend.com',
		// baseUrl: 'http://ipc.daijige.com',
		baseUrl: 'https://demo.daijige.com',
	},
	// 生产环境配置
	production: {
		baseUrl: 'https://demo.daijige.com',
	}
}
export default CONFIG['development'];
