<template>
    <!-- <div class="payment-box" :style="'flex: ' + flex + ';'"> -->
    <div class="payment-box">
        <!-- <h4>{{ title }}</h4> -->
        <div class="select-state-title" v-if="title">
            <div class="title-wrap">
                <h3 class="title">{{title}}</h3>
                <slot name="desc" />
            </div>
            <!-- <div>
                <button @click="callService">
                    <i class="el-icon-service"></i>联系客服
                </button>
            </div> -->
            <div class="btn-wrap">
                <!-- <button v-if="hasService" @click="callService"> -->
                    <i v-if="hasService" @click="callService" class="el-icon-service"></i>
                    <!-- 联系客服 -->
                <!-- </button> -->
                <!-- <button v-if="hasPlaceBtn" @click="gotoOrder">
                    <i class="el-icon-edit-outline"></i>下单
                </button> -->
            </div>
        </div>
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // flex: {
        //   type: [String, Number],
        //   default: 1
        // },
        bg: {
            type: String,
            default: '#ffffff'
        },
        title: {
            type: String,
            default: ''
        },
        hasService: {
            type: [String, Boolean],
            default: false
        }
    },
    data () {
        return {
        }
    },
    methods: {
        callService () {
            this.$func.initBaiduBridge()
        },
    }
}
</script>

<style lang="scss" scoped>
.payment-box {
    // margin: 15px;
    // width: calc(100% - 300);
    // max-width: 1200px;
    // width: 100%;
    background-color: #fff;
    // box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.3);

    // box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.1);
    .select-state-title {
        padding: 10px 20px;
        // border-bottom: solid 1px #eaeaee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        .title-wrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .title {
                font-size: 16px;
                font-weight: 500;
            }
            .btn-wrap {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
            }
            // button {
            //     font-size: 16px;
            //     outline: 0;
            //     border: none;
            //     background: #20ceab;
            //     color: white;
            //     border-radius: 3px;
            //     padding: 0.6rem 1.2rem;
            //     display: flex;
            //     align-items: center;
            //     cursor: pointer;
            //     margin-left: 20px;
                i {
                    // font-size: 16px;
                    font-size: 24px;
                    margin-right: 0.3rem;
                    color: #454545;
                    opacity: .7;
                }
            // }
        }
        
    }
    h4 {
        // background: #f5f5f5;
        background: #fff;
        font-size: 14px;
        // padding: 10px 15px;
        padding: 20px 15px;
        width: 100%;
        // font-size: 30px;
        // border-bottom: 1px solid #eeeeee;
    }
    .content {
        width: 100%;
        // min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
}
</style>