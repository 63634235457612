<template>

    <div>

        <ModalBox width="500" title="活动规则" v-show="showModal" @cancel="showModal=false">
            <div class="modal-main">
                <p>1、优惠券仅能在本平台内下单使用</p>
                <p>2、不同品类的优惠券不能跨品类使用</p>
                <p>3、每个订单只能使用一张优惠券，不可叠加</p>
                <p>4、优惠券必须在有效期内使用</p>
                <p>5、优惠券不兑换优惠，不找零</p>
                <p>6、优惠券因订单关闭等不可抗因素使用失败，不予退还</p>
                <p>7、有任何疑问请咨询客服电话：0573-83377880</p>
                <p>8、在法律允许范围内，最终解释权归呆鸡哥本平台所有。</p>
            </div>
        </ModalBox>

        <div class="content">
            <div class="rules" type="text" @click="showModal=true">优惠券使用规则</div>

            <div class="nav">
                <el-button 
                    class="nav-tab"
                    type="text" 
                    :class="{'buttonclick': navType == item.type}" 
                    v-for="item of couponTypeOptions" 
                    :key="item.type"
                    @click='handleType(item.type)' 
                >
                    {{ item.title }}
                </el-button>
            </div>

            <div class="main">
                <CouponItem :item="item" v-for="(item,index) in couponList" :key="index" @use="useCoupon" />
            </div>

            <pagination class="pagination-container" :total="pageData.totalCount" :page.sync="pageData.pageNo" :limit.sync="pageData.pageSize" @pagination="pageFn" />
        </div>
        
    </div>

</template>

<script>

export default {
    data () {
        return {
            showModal: false,
            navType: 1,
            couponList: [],
            totalPage: 0,

            pageData: {
                pageNo: 1,
                pageSize: 16,
                totalCount: 0,
            },

            couponTypeOptions: [
                {
                    title: '全部优惠券',
                    type: 1,
                },
                {
                    title: '未使用',
                    type: 2,
                },
                {
                    title: '已使用',
                    type: 3,
                },
                {
                    title: '已过期',
                    type: 4,
                },
            ]
        };
    },
    created () {
        this.getCoupon(this.navType);
    },

    methods: {

        handleType (type) {
            this.navType = type;
            this.pageData.pageNo = 1;
            this.getCoupon(type)
        },

        pageFn (data) {
            this.findOrderList();
        },

        async getCoupon (navType) {
            const params = {
                type: navType,
                page: this.pageData.pageNo,
                limit: this.pageData.pageSize,
            }
            let data = await this.$fetch(this.$api.purse.walletCoupon, params)
            this.couponList = data.list;
            this.pageData.totalCount = data.totalCount;
        },

        useCoupon (item) {
            console.log(item)
            // this.$router.push({
            //   path: "/order/list/",
            //   query: { statusCoupon: 300 },
            // });
            this.$emit('use', item)
        },
    },
};
</script>

<style lang="less" scoped>
.modal-main {
    p {
        line-height: 2;
    }
}
.content {
    min-height: 600px;
    // font-family: Microsoft YaHei;
    .rules {
        cursor: pointer;
        text-align: right;
        margin-top: 8px;
        // font-size: 14px;
        font-size: 16px;
        font-weight: 400;
        color: #ee685d;
    }
    .nav {
        .nav-tab {
            margin-left: 0;
            margin-right: 30px;
            // font-size: 14px;
            font-size: 16px;
            font-weight: 400;
            color: #909090;
        }
        
        .buttonclick {
            padding-bottom: 4px;
            font-weight: bold;
            color: #20ceab !important;
            border-bottom: #20ceab solid 3px;
            border-radius: 0;
        }
    }
    .main {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>