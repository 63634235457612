<template>
    <div class="modal-box">
        <div class="temp-sel-box" :style="'width: ' + width +'px;'">
            <div class="title">
                <h4>{{title}}</h4>
                <i @click="handleCancel" class="el-icon-close"></i>
            </div>

            <div class="main">
                <slot />
            </div>

        </div>
    </div>
</template>

<script>
export default {
    
    name: 'ModalBox',

    props: {
        title: {
            type: String,
            default: ''
        },
        width: {
            type: [Number, String],
            default: 600
        }
    },

    data: () => ({
    }),

    methods: {
        handleCancel () {
            this.$emit('cancel')
        }
    }

}
</script>

<style lang="scss" scoped>
.modal-box {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal-box > div {
    background: white;
    // background: #f8f8f8;
    width: 1000px;
    border-radius: 4px;
}
.title {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    border-bottom: solid 1px #eaeaee;
}

.title h4 {
    padding: 0 10px;
    border-left: solid 2px #20ceac;
    margin: 0 10px;
}

.title i {
    color: #bebebe;
}

.title i:hover {
    cursor: pointer;
    color: #45e7c7;
}

.main {
    // padding: 15px 20px;
    padding: 15px 20px 30px;
    max-height: 50rem;
    overflow: auto;
}
</style>