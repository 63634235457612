<template>
    <div class="user-center-wrap">
        <Navtop :showNavItem="false" />

        <div class="content-wrap">
            <!-- <Sidebar v-if="hasSidebar" class="side-nav" /> -->

            <div class="page-info" :style="{'backgroundColor': bg}">
                <PageTitle v-if="title" :title="title" :hasPlaceBtn="hasPlaceBtn" :hasService="hasService" />
                <div class="details">
                    <slot />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import PageTitle from './PageTitle'
// import Sidebar from './Sidebar'
export default {
    components: {
        PageTitle,
        // Sidebar
    },
    props: {
        bg: {
            type: String,
            default: '#ffffff'
        },
        title: {
            type: String,
            default: ''
        },
        hasSidebar: {
            type: Boolean,
            default: true
        },
        hasPlaceBtn: {
            type: Boolean,
            default: true
        },
        hasService: {
            type: Boolean,
            default: false
        },
    },

}
</script>

<style lang="scss" scoped>
.user-center-wrap {
    width: 100%;
    background: #f5f6fa;
}
.content-wrap {
    width: 1920px;
    min-height: 800px;
    box-sizing: border-box;
    padding: 1.5rem 30px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    .side-nav {
        flex: 0 0 200px;
        margin-right: 30px;
        border-radius: 10px;
        background: #fff;
        overflow: hidden;
        // padding-bottom: 200px;
        height: inherit;
    }
    .page-info {
        flex: 1;
        padding: 10px 0;
        margin: 0 30px 100px 0;
        background: #fff;
        border-radius: 10px;
        .details {
            margin: 1rem;
        }
    }
}
</style>