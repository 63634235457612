import { getToken, setToken, removeToken } from '@/common/auth'
import Vue from 'vue'

const _this = Vue.prototype
const getDefaultState = () => {
    return {
        token: getToken(),
        userInfo: {}
    }
}

const state = getDefaultState()

const mutations = {
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    }
}

const actions = {

    // user login
    login ({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            _this.$fetch(_this.$api.user.login, userInfo).then(response => {
                const { token } = response
                commit('SET_TOKEN', token)
                setToken(token)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 短信登录
    smsLogin ({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            _this.$fetch(_this.$api.user.smsLogin, userInfo).then(response => {
                const { token } = response
                commit('SET_TOKEN', token)
                setToken(token)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    getUserInfo ({ commit }) {
        return new Promise((resolve, reject) => {
            _this.$fetch(_this.$api.user.getUserInfo).then(response => {
                commit('SET_USER_INFO', response)
                resolve(response)
            }).catch(error => {
                removeToken()
                commit('RESET_STATE')
                reject(error)
            })
        })
    },

    // set user info
    editUserInfo ({ commit, dispatch }, params) {
        return new Promise((resolve, reject) => {
            _this.$fetch(_this.$api.user.editUserInfo, params).then(response => {
                // commit('SET_USER_INFO', response)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout ({ commit, state }) {
        return new Promise((resolve, reject) => {
            _this.$fetch(_this.$api.user.logout).then(response => {
                removeToken() // must remove  token  first
                commit('RESET_STATE')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken ({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
