import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/common/auth'
import { toH5, isValid, isWhitePath, redirectSeo } from '@/common/jump'

router.beforeEach(async (to, from, next) => {
    toH5(to.path)
    if (!isValid(to.path)) return
    if (!getToken()) {
        if (isWhitePath(to.path)) {
            next()
        } else {
            next('/login/')
        }
        return
    }
    if (!store.getters.token) {
        store.commit('user/SET_TOKEN', getToken())
    }
    // 登录页和注册页不需要请求userInfo，因为逻辑上这两步不需要userInfo，进入页面才需要
    if (to.path === '/login' || to.path === '/login/') {
        // next('/')
        next('/order/list')
        return
    }
    const hasUserInfo = store.getters.userId
    const p = hasUserInfo ? Promise.resolve() : store.dispatch('user/getUserInfo')
    p.then(res => {
        next()
    }).catch(error => {
        Message.error(error || '网络问题')
        // 除了注册和登录页面，其他所有页面都需要userInfo。
        // 在有token的情况下，请求不到userInfo，不合理，因为登录账号必然有userInfo。
        // 所以重置token和userInfo，跳转到首页未登录状态
        store.dispatch('user/resetToken').then(res => {
            next('/')
            return
        })
    })
})

router.afterEach(() => {
    // finish progress bar
    // NProgress.done()
})
