<template>
    <PopupBox 
        :isPopupShow="value" 
        confirmText="确定付款" 
        cancelText="" 
        @handleConfirmButton="walletPay" 
        @handleCancelButton="walletCancel" 
        @handleClosePopup="walletCancel"
    >
        <div class="wallet-box">
            <div class="show-money">
                <p>您的钱包当前余额为：￥{{money}}</p>
                <p>当前需支付：<b>￥{{requiredPay}}</b> </p>
            </div>

            <div class="password-wrap">
                <p>请输入您的钱包密码</p>
                <el-input class="password-input" v-model="password" type="password" maxlength="6"></el-input>
                <span class="forget">
                    <router-link to="/wallet/list/?change=false">
                        <el-button type="text" class="forgetbtn">忘记密码?</el-button>
                    </router-link>
                </span>
            </div>
        </div>
    </PopupBox>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        rid: {
            type: [Number, String],
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        },
        // orderInfo: {
        //     type: Object,
        //     default: () => {
        //         return {}
        //     }
        // },
        requiredPay: {
            type: [Number, String],
            default: ''
        },
        replenishmentId: {
            type: String,
            default: ''
        },
        orderId: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        password: '',
    }),
    computed: {

        ...mapGetters({
            money: 'money'
        }),

        // isBukuan () {
        //     return this.$route.query.isBukuan
        // },

        isBukuan() {
            return !!this.replenishmentId
        },

    },

    methods: {

        walletCancel (type) {
            this.$emit('input', false)
        },

        async walletPay () {
            if (!this.password) {
                this.$message.error('请输入支付密码');
                return
            }
            let params = {
                // order_id: +this.$route.query.id,
                order_id: +this.orderId,
                replenishment_id: this.replenishmentId,
                rid: this.rid, // 优惠券id
                pay_password: this.password,
                // rid: '', // 优惠券id
            }
            console.log(params)
            // return
            const payApi = !this.isBukuan ? this.$fetch(this.$api.purse.walletPay, params) : this.$fetch(this.$api.purse.walletReplenishment, params)
            payApi.then(data => {
                this.$emit('success')
            }).catch(err => {
                this.password = ''
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.password-input {
    /* width: 338px; */
    width: 385px;
}
.password-input /deep/ .el-input__inner {
    height: 48px;
    letter-spacing: 47.5px;
    border: none;
    /* border-top:none ;
        border-bottom:none ; */
    background: url(@/assets/images/picture/mimakuang.png) no-repeat;
    background-position: left;

    background-size: 80% 95%;
}
.wallet-box {
    text-align: left;
    div.show-money {
        margin: 20px 0 10px;
        font-size: 18px;
        p {
            margin: 3px 0;
        }
        p:nth-child(2) {
            font-size: 16px;
            b {
                color: #ee685d;
            }
        }
    }
    div.password-wrap {
        position: relative;
        font-size: 14px;
        margin-bottom: 19px;
        .forget {
            position: absolute;
            z-index: 22;
            background: #ffffff;
            height: 55px;
            top: 35px;
            left: 320px;
            .forgetbtn {
                width: 67px;
                height: 12px;
                font-size: 14px;
                // font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
            }
        }
    }
    div:nth-child(3) {
        text-align: right;
        button {
            background: #20ceab;
            outline: 0;
            border: none;
            padding: 10px 30px;
            border-radius: 5px;
            color: white;
            margin: 8px 0 30px;
        }
    }
}
</style>