<template>
    <div class="select-state-title">
        <div>
            <h3>{{title}}</h3>
        </div>
        <!-- <div>
            <button @click="callService">
                <i class="el-icon-service"></i>联系客服
            </button>
        </div> -->
        <div class="btn-wrap">
            <button v-if="hasService" @click="callService">
                <i class="el-icon-service"></i>联系客服
            </button>
            <button v-if="hasPlaceBtn" @click="gotoOrder">
                <i class="el-icon-edit-outline"></i>下单
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        hasPlaceBtn: {
            type: Boolean,
            default: true
        },
        hasService: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
    }),
    computed: {
        ...mapGetters({
            userId: 'userId'
        }),
    },
    methods: {
        callService () {
            this.$func.initBaiduBridge()
        },
        gotoOrder () {
            if (!this.userId) {
                this.$router.push({ path: '/login/' })
                return;
            }
            //   if (sessionStorage.getItem("orderInfo")) {
            //     sessionStorage.removeItem("orderInfo");
            //     // sessionStorage.removeItem("activeData");
            //   }
            this.$router.push({ path: '/place' })
        }
    }
}
</script>

<style lang="scss" scoped>
.select-state-title {
    padding: 10px 20px;
    border-bottom: solid 1px #eaeaee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
        padding: 0 10px;
        border-left: solid 2px #20ceab;
        margin: 20px 0;
    }
    .btn-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
    button {
        font-size: 16px;
        outline: 0;
        border: none;
        background: #20ceab;
        color: white;
        border-radius: 3px;
        padding: 0.6rem 1.2rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;
        i {
            font-size: 16px;
            margin-right: 0.3rem;
        }
    }
}
</style>