<template>
    <transition name="fade">

        <div class="modal-box">
            <div class="modal-content">
                <div class="title-wrap">
                    <h3 v-if="title">{{title}}</h3>
                    <i v-if="showClose" class="el-icon-close" @click="handleClosePopup"></i>
                </div>
                <!-- <div class="main" v-if="checkModalBox.type=='cancel'"> -->
                <div class="main">
                    <!-- <slot /> -->
                    <div>{{ message }}</div>
                    <div class="btn-box" :class="{ 'middle': singleBtn }">
                        <button v-if="showConfirmButton" :class="{'success': confirmType == 'success', 'error': confirmType == 'error', 'default': confirmType == 'default'}" @click="handleConfirmButton">
                            {{confirmButtonText}}
                        </button>
                        <button v-if="showCancelButton" :class="{'success': cancelType == 'success', 'error': cancelType == 'error', 'default': cancelType == 'default'}" @click="handleCancelButton">
                            {{cancelButtonText}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </transition>
</template>

<script>
export default {
    filters: {
    },
    props: {

        confirmType: {
            type: String,
            default: 'success'
        },

        cancelType: {
            type: String,
            default: 'default'
        },
        title: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        showClose: {
            type: Boolean,
            default: true,
        },
        confirmButtonText: {
            type: String,
            default: '确认取消'
        },
        cancelButtonText: {
            type: String,
            default: '我再想想'
        },
        showConfirmButton: {
            type: Boolean,
            default: true,
        },
        showCancelButton: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
    }),
    computed: {
        singleBtn () {
            if (this.showConfirmButton && this.showCancelButton) return false
            return true
        }
    },
    methods: {

        handleConfirmButton () {
            // this.$emit('close')
            this.$emit('handleConfirmButton')
        },

        handleCancelButton () {
            // this.$emit('close')
            this.$emit('handleCancelButton')
        },

        handleClosePopup () {
            console.log('aaaaaaaaaaaaaaaaa')
            this.$emit('close')
            // close()
            // this.$emit('handleClosePopup')
        },

    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.modal-box {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .modal-content {
        background: white;
        width: 31rem;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        .title-wrap {
            position: relative;
            text-align: center;
            padding: 15px 10px;
            h3 {
                padding: 0 10px;
                // border-left: solid 2px #20ceac;
                margin: 0 10px;
            }
            i.el-icon-close {
                position: absolute;
                right: 10px;
                top: 15px;
                color: #bebebe;
                font-size: 20px;
                &:hover {
                    cursor: pointer;
                    color: #45e7c7;
                }
            }
        }
        .main {
            padding: 15px 50px;
            line-height: 24px;
            .btn-box {
                display: flex;
                // justify-content: center;
                justify-content: space-between;
                margin: 40px 0 25px;
                &.middle {
                    justify-content: center;
                }
                h3 {
                    color: #333333;
                }
                button {
                    flex: 0 0 40%;
                    box-sizing: border-box;
                    // padding: 0 10px;
                    width: 100%;
                    // border: none;
                    outline: 0;
                    color: #ffffff;
                    background: white;
                    font-size: 16px;
                    // padding: 0.7rem 2.8rem;
                    padding: 0.7rem 0rem;
                    border-radius: 5px;
                    &.error {
                        border: 1px solid #ee685d;
                        background: #ee685d;
                    }
                    &.success {
                        border: 1px solid #20ceab;
                        background: #20ceab;
                    }
                    &.default {
                        color: #333;
                        // border: 1px solid #999;
                        border: none;
                        background: #fff;
                    }
                    // &.warning {
                    //     background: #ee685d;
                    // }
                    // &.info {
                    //     background: #ee685d;
                    // }
                    // &.default {
                    //     background-color: #ffffff;
                    // }
                }
                // .confirm {
                //     color: white;
                //     background: #20ceab;
                // }

                // .red-cancel {
                //     background: #ee685d;
                //     color: white;
                // }
            }
        }
    }
}
</style>