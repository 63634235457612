const getters = {
    sidebar: state => state.app.sidebar,
    token: state => state.user.token,
    userId: state => state.user.userInfo.id,
    username: state => state.user.userInfo.username,
    suserBase_name: state => state.user.userInfo.suserBase_name,
    avatar: state => state.user.userInfo.avatar,
    mobile: state => state.user.userInfo.mobile,
    money: state => state.user.userInfo.money,
    vip_id_name: state => state.user.userInfo.vip_id_name,
    deduct_count: state => state.user.userInfo.deduct_count,
    coupon_count: state => state.user.userInfo.coupon_count,
    msg_count: state => state.user.userInfo.msg_count,
    ruleList: state => state.user.userInfo.rule_list,

    defaultOpeneds: state => state.sidebar.defaultOpeneds,
}
export default getters
