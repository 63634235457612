
export default {
    cdn: 'https://cdn.daijige.com',
    imageView2: '?imageView2/1/w/300/h/300',
    imageView3: '?imageView2/1/w/180/h/150',
    orderEmpty() {
		return {
			order_id: "",
			cate_id: "",
			cate_name: "",
			servertype_list: "",
			server_id: "",
			server_name: "",
			offer_type: '',
			order_price: 0,
			goods_params: [],
			client_data: {
				client_name: '',
				client_tel: '',
				client_address: '',
				client_remarks: '',
				client_province: '',
				client_province_name: '',
				client_city: '',
				client_city_name: '',
				client_district: '',
				client_district_name: '',
			},
			logistics_data: {
				// is_elevator: '有',
				is_elevator: '',
				floors: '',
				// is_goods_arrived: '已到货',
				is_goods_arrived: '',
				expected_arrival_date: '',
				pick_tel: '',
				pick_address: '',
				special_remarks: ''
			},
			server_data: {
				master_id: 0,
				// server_start: '',
				// server_end: '',
				expect_install_time: '',
				is_convert_bid: 1,
				server_contact_user: '',
				server_contact_tel: '',
			}
		}
	},
	
	goodsEmpty() {
		return {
			shoppingTwoId: '',
			shoppingTwoName: '',
			shoppingThreeId: '',
			shoppingThreeName: '',
			shoppingImg: [],
			video: "",
			remarks: "",
			goods_number: 1,
			unit: '个',
			price: 0,
			// goods_model: [],
			goods_sku: '',
			attribute_id: ''
		}
	},
    
}
