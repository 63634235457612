<template>
    <div class="banner" :class="{
      'cursor-pointer': bannerType == 2 || bannerType == 7,
    }">
        <el-carousel :height="bannerHeight + 'px'" :interval="100000">
            <el-carousel-item v-for="(banner, i) in banners" :key="i">
                <!-- <view class="banner-place-btn">立即下单找师傅</view> -->
                <div v-if="$route.path==='/'" class="banner-place-btn" @click="toPlace"></div>
                <!-- <el-image v-if="$route.path==='/'" class="banner-place-btn" src="@/assets/images/picture/click-master.png" @click="toPlace" /> -->
                <el-image class="img" :src="$constant.cdn + banner.image" alt="" fit="cover" @click="toPlace" />
                <!-- <el-image class="img" src="@/assets/images/picture/banner1.png" alt="" fit="cover" @click="toPlace" /> -->
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        bannerType: [Number, String],
        bannerHeight: {
            type: [String, Number],
            default: 500
        },
        isFullScreen: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        banners: [],
    }),
    computed: {
        ...mapGetters({
            userId: 'userId'
        }),
    },
    mounted () {
        this.getBanner();
    },
    watch: {
    },
    methods: {

        async getBanner () {
            let data = await this.$fetch(this.$api.index.indexBanner, { type: this.bannerType })
            this.banners = data
        },
        toPlace () {
            if (!this.userId) {
                this.$router.push({
                    path: this.$route.path, query: {
                        log_type: 'pwd_login',
                        place: true
                    }
                })
                return;
            }
            if (this.bannerType == "2" || this.bannerType == "7") {
                this.$router.push({ path: "/place" });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/* banner */
.banner {
    position: relative;
    width: 100%;
    /* height: 800px; */
}

.banner.cursor-pointer {
    cursor: pointer;
}

.banner .img {
    width: 100%;
    height: 100%;
}
.banner-place-btn {
    position: absolute;
    width: 320px;
    height: 70px;
    bottom: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: url("@/assets/images/picture/click-master.png") no-repeat center center;
    /* background-size: 100%; */
    background-size: cover;
    /* position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 99999; */
    &:hover {
        background-image: url("@/assets/images/picture/click-master-hover.png");
        // background: url('@/assets/images/picture/click-master-hover.png') no-repeat center center;
    }
}
</style>